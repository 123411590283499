<template>
  <div class="col-ms-12 col-md-5 col-lg-5 col-xl-5">
    <v-card class="rounded-lg product-card">
      <v-card-title class="modern-title">{{
        $t("product_detail")
      }}</v-card-title>
      <v-card-text class="text-center pb-2 image-container">
        <v-avatar
          @click="openURL"
          tile
          size="300"
          :color="Img ? 'white' : '#f8f9fa'"
          class="rounded-lg product-image-wrapper"
        >
          <v-img
            v-if="Img"
            contain
            :src="Img"
            width="500"
            height="400"
            class="product-image"
          />
          <div v-else class="placeholder-image">
            <v-icon size="48" color="grey lighten-1" class="mb-2"
              >mdi-image</v-icon
            >
            <h2 class="placeholder-text black--text">500x400</h2>
          </div>
        </v-avatar>

        <div class="image-info">
          <p class="pt-2 mb-0 image-size">{{ $t("size") }} 500x400</p>
          <p class="mt-0 image-format">{{ $t("extension") }} JPEG, JPG, PNG</p>
        </div>
      </v-card-text>
      <v-card-text class="pt-0 product-info">
        <v-divider class="px-4 mt-0 mb-4" />

        <!-- Product details list -->
        <div class="info-list">
          <AppTitle title="barcode" :value="form.code" />
          <AppTitle title="product_name" :value="form.name" />

          <AppTitle title="stock" :value="form.stock | ToNumber" />
          <AppTitle
            title="notify_stock"
            :value="form.notify_stock | ToNumber"
          />

          <AppTitle title="price_buy" :value="form.price_buy | NumberFormat" />
          <AppTitle
            title="profit_percent"
            :value="profit_percent | NumberFormat"
            :badge="true"
            :badge-color="profit_percent > 0 ? 'success' : 'error'"
          />
          <AppTitle
            title="price_sale_with_profit"
            :value="form.price_sale | NumberFormat"
          />

          <!-- Currency section (conditional) -->
          <template v-if="!is_lak">
            <AppTitle title="cost_currency" :value="exchange_rate" />
            <AppTitle
              title="price_buy_in_lak"
              :value="price_buy | NumberFormat"
            />
            <AppTitle
              title="price_sale_in_lak"
              :value="price_sale | NumberFormat"
            />
          </template>
          <template v-if="taxInclude">
            <AppTitle title="tax" :value="tax_show" />
            <AppTitle
              title="price_with_tax"
              :value="price_with_tax | NumberFormat"
            />
          </template>

          <AppTitle
            title="category"
            :value="category"
            :chip="true"
            chip-color="primary"
          />
          <AppTitle
            title="unit"
            :value="size"
            :chip="true"
            chip-color="success"
          />

          <AppTitle title="description" :value="form.desc" :divider="false" />
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import AppTitle from "@/components/settings/app_title";
import { myMixin } from "@/mixin/main";
export default {
  mixins: [myMixin],
  components: {
    AppTitle,
  },
  props: {
    form: {
      required: true,
      type: Object,
    },
    imgFile: {
      required: true,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    currencies: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    Img() {
      // check if this.imgFile type is a string and includes http
      const type = typeof this.imgFile;
      if (this.imgFile && type === "string" && this.imgFile.includes("http")) {
        return this.imgFile;
      } else if (this.imgFile) {
        let file = null;
        // check if imgFile is a array and contains more then 0 element then select 1st element
        if (Array.isArray(this.imgFile) && this.imgFile.length > 0) {
          file = this.imgFile[0];
        } else {
          file = this.imgFile;
        }
        let imageURL = URL.createObjectURL(file);
        return imageURL;
      } else if (this.form.img) {
        return this.img(this.form);
      } else {
        return null;
      }
    },
    is_lak() {
      // check if form.currency_id is not null and find the currency from the currencies array
      if (this.form && this.form.currency_id && this.currencies.length) {
        const currency = this.currencies.find(
          (currency) => currency.id === this.form.currency_id
        );
        return currency ? currency.code === "LAK" : false;
      } else {
        return false;
      }
    },
    size() {
      if (this.form && this.form.size && this.form.size.name) {
        return this.form.size.name;
      } else if (this.form && this.form.size_id && this.form.size_id.name) {
        return this.form.size_id.name;
      } else {
        return null;
      }
    },
    category() {
      if (this.form && this.form.category && this.form.category.la_name) {
        return this.form.category.la_name;
      } else if (
        this.form &&
        this.form.category_id &&
        this.form.category_id.la_name
      ) {
        return this.form.category_id.la_name;
      } else {
        return null;
      }
    },
    tax_amount() {
      if (this.form && this.form.price_sale) {
        // cvonvert to number and price_sale *
        const price_sale = Number(this.form.price_sale);
        const amt = (price_sale * this.tax_rate) / 100;
        return amt;
      } else {
        return 0;
      }
    },
    price_with_tax() {
      if (this.form && this.form.price_sale) {
        // convert to number
        const price_sale = Number(this.form.price_sale);
        const tax_amount = Number(this.tax_amount);
        const total = price_sale + tax_amount;
        return total * this.rate;
      } else {
        return 0;
      }
    },
    profit_percent() {
      if (this.form && this.form.price_buy && this.form.price_sale) {
        // convert to number
        const price_buy = Number(this.form.price_buy);
        const price_sale = Number(this.form.price_sale);
        const profit = price_sale - price_buy;
        const percent = (profit / price_buy) * 100;
        return percent;
      } else {
        return 0;
      }
    },

    exchange_rate() {
      // find the exchange rate from the currencies array by the currency_id
      if (this.form && this.form.currency_id && this.currencies.length) {
        const currency = this.currencies.find(
          (currency) => currency.id === this.form.currency_id
        );
        // | NumberFormat is a filter
        return `${this.$options.filters.NumberFormat(this.rate)}/${
          currency.code
        }`;
      } else {
        return 0;
      }
    },
    rate() {
      const rate = this.getRate(this.form.currency_id, this.currencies);
      return rate;
    },

    price_buy() {
      // check if form.price_buy is not null and calculate the price_buy with the exchange rate
      if (this.form && this.form.price_buy) {
        const price_buy = Number(this.form.price_buy);
        const exchange_rate = Number(this.rate);
        return price_buy * exchange_rate;
      } else {
        return 0;
      }
    },

    price_sale() {
      // check if form.price_sale is not null and calculate the price_sale with the exchange rate
      if (this.form && this.form.price_sale) {
        const price_sale = Number(this.form.price_sale);
        const exchange_rate = Number(this.rate);
        return price_sale * exchange_rate;
      } else {
        return 0;
      }
    },
    tax_show() {
      const tax_amount = Number(this.tax_amount);
      const total = tax_amount * this.rate;
      const total_str = this.$options.filters.NumberFormat(total);
      return `${total_str} ${this.tax}`;
    },
  },
  methods: {
    openURL() {
      // check if isEdit is true
      if (!this.isEdit) {
        //  open the image in a new tab with  Img from computed property
        window.open(this.Img, "_blank").focus();
        return;
      }
      const baseURL = process.env.VUE_APP_BASE_URL;
      const url = `${baseURL}/uploads/${this.form.img}`;
      window.open(url, "_blank").focus();
    },
  },
};
</script>

<style scoped>
.modern-title {
  font-size: 18px;
  font-weight: 600;
  color: #333;
  border-bottom: 1px solid #eaeaea;
  padding-bottom: 16px;
}

/* Image styles */
.image-container {
  padding: 24px 16px 16px;
}

.product-image-wrapper {
  cursor: pointer;
  box-shadow: 0 4px 14px rgba(0, 0, 0, 0.08);
  transition: all 0.3s ease;
  overflow: hidden;
}

.product-image-wrapper:hover {
  transform: scale(1.02);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.12);
}

.product-image {
  border-radius: 8px;
}

.placeholder-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.placeholder-text {
  color: #757575;
  font-weight: 500;
}

.image-info {
  margin-top: 12px;
}

.image-size,
.image-format {
  color: #666;
  font-size: 14px;
}

/* Product info styles */
.product-info {
  padding-top: 0;
}

.info-list {
  margin-top: 8px;
}

.enhanced-row {
  margin-bottom: 8px;
}

.highlight-row {
  font-weight: 600;
}

.section-divider {
  margin: 20px 0 12px;
  padding-bottom: 6px;
  border-bottom: 1px dashed #e0e0e0;
  display: flex;
  align-items: center;
}

.section-title {
  font-size: 14px;
  font-weight: 600;
  color: #555;
  display: flex;
  align-items: center;
}

/* Add this to your AppTitle component or override it with deeper selectors if needed */
:deep(.v-list-item) {
  padding: 8px 0;
  border-bottom: 1px solid #f5f5f5;
}

:deep(.v-list-item__title) {
  color: #666;
  font-size: 14px;
}

:deep(.v-list-item__subtitle) {
  font-weight: 500;
  color: #333;
}

/* Make improvements to the text fields */
:deep(.v-text-field__slot input) {
  font-weight: 500;
}

:deep(.v-select__selections) {
  font-weight: 500;
}
</style>
