<template>
  <v-card rounded="lg">
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="items.data"
        :search="search"
        :items-per-page="value ? options.itemsPerPage : per_page"
        item-key="index"
        :loading="isLoading"
        :loading-text="$t('loading')"
        :no-data-text="$t('no_data')"
        hide-default-footer
        fixed-header
      >
        <template slot="progress">
          <v-progress-linear color="primary" indeterminate></v-progress-linear>
        </template>

        <template v-slot:top>
          <slot name="activator"></slot>
        </template>
        <template v-slot:item.logo="{ item }">
          <v-avatar size="40">
            <app-img :img="item.logo" />
          </v-avatar>
        </template>
        <template v-slot:item.img="{ item }">
          <v-avatar tile size="40" class="rounded-lg" color="grey lighten-2">
            <app-img :img="item.img" />
          </v-avatar>
        </template>
        <template v-slot:item.invoiceStatus="{ item }">
          {{ item.status }}
        </template>
        <template v-slot:item.orderStatus="{ item }">
          <v-chip
            small
            label
            dark
            class="text-capitalize"
            :color="orderStatusColor(item.status)"
          >
            {{ $t(item.status) }}
          </v-chip>
        </template>
        <template v-slot:item.is_online_order="{ item }">
          <v-chip
            small
            label
            dark
            class="text-capitalize"
            :color="item.is_online_order ? 'green' : 'primary'"
          >
            {{ item.is_online_order ? $t("online") : $t("counter") }}
          </v-chip>
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip
            small
            label
            dark
            class="text-capitalize"
            :color="orderStatusColor(item.status)"
          >
            {{ $t(item.status) }}
          </v-chip>
        </template>

        <template v-slot:item.qr_code="{ item }">
          <app-img :img="item.qr_code" width="30" height="30" />
        </template>
        <template v-slot:item.proImg="{ item }">
          <app-img :img="item.product_id.img" />
        </template>
        <template v-slot:item.index="{ index }">
          {{ index + 1 }}
        </template>
        <template v-slot:item.packName="{ item }">
          <v-chip label dark :color="packageName(item.name).color" small>
            {{ packageName(item.name).text }}</v-chip
          >
        </template>
        <template v-slot:item.blockStatus="{ item }">
          <p>
            {{ item.status }}
          </p>
        </template>

        <template v-slot:item.avatar="{ item }">
          <v-avatar size="35">
            <app-img :img="IMAGES(item.avatar)" />
          </v-avatar>
        </template>
        <template v-slot:item.icon="{ item }">
          <v-avatar size="33" color="#f0f0f0">
            <v-icon small>{{ item.icon }}</v-icon>
          </v-avatar>
        </template>

        <template v-slot:item.avatarUser="{ item }">
          <v-avatar size="35">
            <app-img :img="IMAGES(item.user.avatar)" />
          </v-avatar>
        </template>
        <template v-slot:item.user_stores_avatar="{ item }">
          <v-avatar size="35">
            <app-img :img="IMAGES(item.user_stores.user.avatar)" />
          </v-avatar>
        </template>

        <template v-slot:item.unit="{ item }">
          <v-chip
            label
            dark
            :color="
              item.unit === 'amount' ? 'orange accent-2' : 'green accent-3'
            "
            small
          >
            {{ $t(item.unit) }}</v-chip
          >
        </template>
        <template v-slot:item.product_unit="{ item }">
          <v-chip
            label
            dark
            :color="
              item.size.unit === 'amount' ? 'orange accent-2' : 'green accent-3'
            "
            small
          >
            {{ $t(item.size.unit) }}</v-chip
          >
        </template>
        <template v-slot:item.proStatus="{ item }">
          <v-btn
            text
            @click="proStatus(item)"
            :color="item.is_active ? 'red' : 'blue'"
          >
            <v-icon left :color="item.is_active ? 'red' : 'blue'"
              >fal fa-{{ item.is_active ? "toggle-off" : "toggle-on" }}</v-icon
            >
            {{ item.is_active ? $t("open") : $t("close") }}
          </v-btn>
        </template>
        <template v-slot:item.packageName="{ item }">
          <v-chip
            label
            dark
            :color="packageName(item.package.name).color"
            small
          >
            {{ packageName(item.package.name).text }}</v-chip
          >
        </template>
        <template v-slot:item.branchStatus="{ item }">
          <v-btn
            @click="setBranch(item)"
            depressed
            dark
            small
            :color="branchStatus(item).color"
          >
            <v-icon dark left small>{{ branchStatus(item).icon }}</v-icon>
            {{ $t(branchStatus(item).text) }}
          </v-btn>
        </template>
        <template v-slot:item.statusUserStore="{ item }">
          <v-chip
            label
            dark
            :color="
              statusUserStore(item).status
                ? statusUserStore(item).color
                : statusUserStore(item).color
            "
            small
          >
            {{ $t(statusUserStore(item).title) }}</v-chip
          >
        </template>
        <template v-slot:item.statusBranch="{ item }">
          <v-chip label dark :color="item.status ? 'green' : 'red'" small>
            {{ item.status ? "Acitve" : "inActive" }}</v-chip
          >
        </template>
        <template v-slot:item.isDemo="{ item }">
          <v-chip label dark :color="userPackage(item.stores).color" small>
            {{ $t(userPackage(item.stores).text) }}</v-chip
          >
        </template>
        <template v-slot:item.statusUser="{ item }">
          <v-chip label dark :color="item.is_active ? 'green' : 'red'" small>
            {{ item.is_active ? "ໃຊ້ງານ" : "ປິດການໃຊ້ງານ" }}</v-chip
          >
        </template>
        <template v-slot:item.mobileSupport="{ item }">
          <v-chip label dark :color="item.mobile ? 'green' : 'red'" small>
            {{ item.mobile ? "Acitve" : "inActive" }}</v-chip
          >
        </template>
        <template v-slot:item.productStatus="{ item }">
          <v-chip label dark :color="orderStatusColor(item.status)" small>
            {{ $t(item.status) }}
          </v-chip>
        </template>
        <template v-slot:item.statusFB="{ item }">
          <v-chip label dark :color="item.status ? 'green' : 'red'" small>
            {{ item.status ? "ອ່ານແລ້ວ" : "ຍັງ" }}</v-chip
          >
        </template>

        <template v-slot:item.userRole="{ item }">
          <v-chip
            dark
            :color="RoleColor(item.role)"
            small
            class="text-capitalize"
            label
          >
            {{ userRole(item.role) }}</v-chip
          >
        </template>
        <template v-slot:item.is_active="{ item }">
          <v-chip
            dark
            :color="item.is_active ? 'green' : 'red'"
            small
            class="text-capitalize"
            label
          >
            {{ item.is_active ? $t("active") : $t("inactive") }}</v-chip
          >
        </template>

        <template v-slot:item.userStatus="{ item }">
          <v-chip
            small
            label
            class="text-capitalize white--text"
            :color="RoleColor(item.role)"
          >
            {{ userRole(item.role) }}</v-chip
          >
        </template>
        <template v-slot:item.handler="{ item }">
          <v-chip label :color="item.user ? 'grey' : 'red'" small>
            {{ item.user ? item.user.first_name : "No" }}</v-chip
          >
        </template>

        <template v-slot:item.typeExpense="{ item }">
          <v-chip
            label
            :color="item.type === 'income' ? 'green' : 'red'"
            small
            class="white--text"
          >
            {{ $t(item.type) }}</v-chip
          >
        </template>
        <template v-slot:item.imgCurrency="{ item }">
          <v-avatar size="35">
            <v-img :src="item.img" />
          </v-avatar>
        </template>
        <template v-slot:item.avatar="{ item }">
          <v-avatar size="35">
            <app-img :img="item.avatar" />
          </v-avatar>
        </template>
        <template v-slot:item.storeImg="{ item }">
          <v-avatar size="35">
            <app-img :img="item.store.logo" />
          </v-avatar>
        </template>
        <template v-slot:item.storeImgLogo="{ item }">
          <v-avatar size="35">
            <app-img :img="item.logo" />
          </v-avatar>
        </template>
        <template v-slot:item.storeImgs="{ item }">
          <v-avatar size="35">
            <app-img :img="item.stores ? item.stores.logo : ''" />
          </v-avatar>
        </template>
        <template v-slot:item.storelOGO="{ item }">
          <v-avatar size="35">
            <app-img :img="item.store.logo" />
          </v-avatar>
        </template>

        <template v-slot:item.created_at="{ item }">{{
          item.created_at | created_at
        }}</template>
        <template v-slot:item.category="{ item }">{{
          $i18n.locale === "en" ? item.category.en_name : item.category.la_name
        }}</template>
        <template v-slot:item.date="{ item }">{{
          item.date | created_at
        }}</template>
        <template v-slot:item.orderDate="{ item }">{{
          item.created_at | moment
        }}</template>

        <template v-slot:item.deleted_at="{ item }">{{
          item.deleted_at | created_at
        }}</template>
        <template v-slot:item.history_sum_qty="{ item }">{{
          item.history_sum_qty ? item.history_sum_qty : 0
        }}</template>
        <template v-slot:item.proCreated="{ item }">{{
          item.product_id.created_at | created_at
        }}</template>
        <template v-slot:item.updated_at="{ item }">{{
          item.updated_at | created_at
        }}</template>
        <template v-slot:item.price="{ item }">{{
          item.price | NumberFormat
        }}</template>
        <template v-slot:item.tickets_sum_total_price="{ item }">{{
          item.tickets_sum_total_price | NumberFormat
        }}</template>
        <template v-slot:item.tickets_sum_total_paid="{ item }">{{
          item.tickets_sum_total_paid | NumberFormat
        }}</template>
        <template v-slot:item.price_sale="{ item }">{{
          item.price_sale | NumberFormat
        }}</template>
        <template v-slot:item.sum_price_buy="{ item }">{{
          item.sum_price_buy | NumberFormat
        }}</template>
        <template v-slot:item.sum_price_sale="{ item }">{{
          item.sum_price_sale | NumberFormat
        }}</template>
        <template v-slot:item.price_buy="{ item }">{{
          item.price_buy | NumberFormat
        }}</template>
        <template v-slot:item.is_show="{ item }">{{
          item.is_show ? $t("yes") : $t("no")
        }}</template>
        <template v-slot:item.is_online="{ item }">{{
          item.is_online ? $t("yes") : $t("no")
        }}</template>
        <template v-slot:item.products_sale_sum_total_price="{ item }">{{
          item.products_sale_sum_total_price | NumberFormat
        }}</template>
        <template v-slot:item.total_overdue="{ item }">{{
          item.total_overdue | NumberFormat
        }}</template>

        <template v-slot:item.history_sum_price_buy="{ item }">{{
          item.history_sum_price_buy | NumberFormat
        }}</template>
        <template v-slot:item.total_discount="{ item }">{{
          total_discount(item)
        }}</template>
        <template v-slot:item.stockPriceSale="{ item }">{{
          stockPriceSale(item.history)
        }}</template>

        <template v-slot:item.priceSale="{ item }">{{
          item.product_id.price_sale | NumberFormat
        }}</template>
        <template v-slot:item.total_price="{ item }">{{
          item.total_price | NumberFormat
        }}</template>
        <template v-slot:item.history_buy_sum_total_paid="{ item }">{{
          item.history_buy_sum_total_paid | NumberFormat
        }}</template>
        <template v-slot:item.total_pricePay="{ item }">{{
          item.total_price | NumberFormat
        }}</template>
        <template v-slot:item.point="{ item }">{{
          item.point | NumberFormat
        }}</template>

        <template v-slot:item.totalDiscount="{ item }">{{
          item.discount | NumberFormat
        }}</template>

        <template v-slot:item.storeName="{ item }">{{
          item.stores.name
        }}</template>
        <template v-slot:item.storesStartDate="{ item }">{{
          item.stores ? item.stores.store_id.start_date : ""
        }}</template>
        <template v-slot:item.storesEndDate="{ item }">{{
          item.stores ? item.stores.store_id.end_date : ""
        }}</template>

        <template v-slot:item.priceBuy="{ item }">{{
          item.product_id.price_buy | NumberFormat
        }}</template>
        <template v-slot:item.packagePrice="{ item }">{{
          item.package.price | NumberFormat
        }}</template>
        <template v-slot:item.name="{ item }">{{
          item.name | nameLimit
        }}</template>
        <template v-slot:item.total="{ item }">{{
          item.total | NumberFormat
        }}</template>
        <template v-slot:item.stock="{ item }">{{
          productSize(item)
        }}</template>

        <template v-slot:item.UserRole="{ item }">
          <div v-for="(data, index) in item.roles" :key="index">
            <v-chip label small> {{ data.name }}</v-chip>
          </div>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="viewItem"
                v-bind="attrs"
                v-on="on"
                x-small
                @click="viewItem(item)"
                class="rounded-lg"
                fab
                depressed
              >
                <v-icon color="blue" x-small>fal fa-eye</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("description") }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="editItem"
                v-bind="attrs"
                v-on="on"
                :class="deleteItem ? 'mx-2' : 'ml-2'"
                x-small
                @click="editItem(item)"
                class="rounded-lg"
                fab
                depressed
              >
                <v-icon color="green" x-small>fal fa-edit</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("edit") }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="deleteItem"
                v-bind="attrs"
                v-on="on"
                x-small
                @click="deleteItem(item)"
                fab
                class="rounded-lg"
                depressed
              >
                <v-icon color="red" x-small>fal fa-trash</v-icon>
              </v-btn>
            </template>
            <span>ລົບ</span>
          </v-tooltip>
        </template>

        <template v-slot:item.actionsRole="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="viewItem"
                v-bind="attrs"
                v-on="on"
                x-small
                @click="viewItem(item)"
                fab
                class="rounded-lg"
                depressed
              >
                <v-icon color="blue" x-small>fal fa-eye</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("description") }}</span>
          </v-tooltip>
          <v-tooltip bottom v-if="MyRole('change_password_user')">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="editItem"
                v-bind="attrs"
                v-on="on"
                :class="deleteItem ? 'mx-2' : 'ml-2'"
                x-small
                @click="editItem(item)"
                fab
                class="rounded-lg"
                depressed
              >
                <v-icon color="green" x-small>fal fa-key</v-icon>
              </v-btn>
            </template>
            <span>{{ userActiveOption(item).text }}</span>
          </v-tooltip>
          <v-tooltip bottom v-if="MyRole('inactive_user')">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="deleteItem"
                v-bind="attrs"
                v-on="on"
                x-small
                @click="deleteItem(item)"
                fab
                class="rounded-lg"
                depressed
              >
                <v-icon :color="userActiveOption(item).color" x-small>{{
                  userActiveOption(item).icon
                }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t(userActiveOption(item).text) }}</span>
          </v-tooltip>
          <v-tooltip bottom v-if="MyRole('add_user_menu')">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="roleSetting"
                v-bind="attrs"
                v-on="on"
                x-small
                @click="roleSetting(item)"
                fab
                class="rounded-lg ml-2"
                depressed
              >
                <v-icon color="primary" x-small>fal fa-user-cog</v-icon>
              </v-btn>
            </template>
            <span>ສິດທິ</span>
          </v-tooltip>
        </template>

        <template v-slot:item.actionsProducts="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="viewItem"
                v-bind="attrs"
                v-on="on"
                x-small
                @click="viewItem(item)"
                fab
                class="rounded-lg"
                depressed
              >
                <v-icon color="blue" x-small>fal fa-eye</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("description") }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                v-if="editItem && MyRole('edit_product')"
                @click="editItem(item)"
                fab
                class="rounded-lg mx-2"
                x-small
                depressed
              >
                <v-icon x-small color="green">fal fa-edit</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("edit") }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="deleteItem && MyRole('delete_product')"
                v-bind="attrs"
                v-on="on"
                @click="deleteItem(item)"
                fab
                class="rounded-lg"
                x-small
                depressed
              >
                <v-icon color="red" x-small>fal fa-trash</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("delete") }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.actionsBill="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="viewItem"
                v-bind="attrs"
                v-on="on"
                x-small
                @click="viewItem(item)"
                fab
                class="rounded-lg"
                depressed
              >
                <v-icon color="blue" x-small>fal fa-eye</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("description") }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="editItem"
                v-bind="attrs"
                v-on="on"
                x-small
                class="rounded-lg mx-2"
                @click="editItem(item)"
                fab
                depressed
              >
                <v-icon x-small color="green">fal fa-print</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("print") }}</span>
          </v-tooltip>
          <v-tooltip bottom v-if="item.status != 'cancel'">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="deleteItem"
                v-bind="attrs"
                v-on="on"
                x-small
                @click="deleteItem(item)"
                fab
                class="rounded-lg"
                depressed
              >
                <v-icon x-small color="red">fal fa-edit</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("edit") }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.actionsBillUser="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                v-if="viewItem"
                x-small
                @click="viewItem(item)"
                fab
                class="rounded-lg"
                depressed
              >
                <v-icon color="blue" x-small>fal fa-eye</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("description") }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                v-if="editItem"
                x-small
                class="rounded-lg ml-2"
                @click="editItem(item)"
                fab
                depressed
              >
                <v-icon color="green" x-small>fal fa-arrow-right </v-icon>
              </v-btn>
            </template>
            <span>{{ $t("report") }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.actionsOrder="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                v-if="viewItem"
                x-small
                @click="viewItem(item)"
                fab
                class="rounded-lg"
                depressed
              >
                <v-icon color="blue" x-small>fal fa-eye</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("description") }}</span>
          </v-tooltip>
          <v-tooltip
            v-if="
              item.status != 'decline' &&
              item.status != 'completed' &&
              item.status != 'cancel'
            "
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="editItem"
                v-bind="attrs"
                v-on="on"
                x-small
                @click="deleteItem(item)"
                fab
                class="rounded-lg mx-2"
                depressed
              >
                <v-icon x-small color="red"> fal fa-times</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("decline") }}</span>
          </v-tooltip>
          <v-tooltip
            v-if="
              item.status != 'decline' &&
              item.status != 'completed' &&
              item.status != 'cancel'
            "
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="editItem"
                v-bind="attrs"
                v-on="on"
                x-small
                @click="editItem(item)"
                fab
                class="rounded-lg"
                depressed
              >
                <v-icon x-small color="green"> fal fa-check</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("approved") }}</span>
          </v-tooltip>
        </template>
        <template v-if="footer" v-slot:footer>
          <v-data-footer
            :options.sync="options"
            :pagination.sync="page"
            :items-per-page-options="rowsPerPageItems"
            items-per-page-text="$vuetify.dataTable.itemsPerPageText"
          >
            <template v-slot:page-text="pagetext">
              {{ footerPage(pagetext) }}
            </template>
          </v-data-footer>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { myMixin } from "@/mixin/main";

export default {
  props: {
    headers: { required: true, type: Array },
    footer: { type: Boolean, default: true },
    items: { required: true },
    newItem: { required: false, type: Function },
    viewItem: { required: false, type: Function },
    editItem: { required: false, type: Function },
    deleteItem: { required: false, type: Function },
    roleSetting: { required: false, type: Function },
    setBranch: { required: false, type: Function },
    proStatus: { required: false, type: Function },
    commit: { required: false, type: String },
  },
  mixins: [myMixin],
  data() {
    return {
      value: false,
      isLoading: true,
      search: "",
      rowsPerPageItems: [30, 50, 100],
      // rowsPerPageItems: [30],
      options: {
        page: 1,
        itemsPerPage: 30,
      },
    };
  },
  computed: {
    ...mapGetters({
      per_page: "index/per_page",
    }),
    page() {
      const body = {
        page: this.items.current_page,
        itemsPerPage: this.items.per_page,
        pageStart: this.items.from,
        pageStop: this.items.to,
        pageCount: this.items.per_page,
        itemsLength: this.items.total,
      };
      return body;
    },
  },

  mounted() {
    setTimeout(() => {
      this.isLoading = false;
    }, 3000);
  },
  watch: {
    items: {
      handler(val) {
        this.isLoading = false;
      },
      deep: true,
    },

    options(next, pre) {
      const url = Help.paginate(this.items, next, pre);
      const data = {
        url: url,
        commit: this.commit,
        that: this,
      };
      // called the pagination action
      this.fetch(data);
      this.value = true;
    },
  },

  methods: {
    ...mapActions({
      fetch: "index/fetch",
    }),
    ...mapMutations({
      SET_PER_PAGE: "index/SET_PER_PAGE",
    }),
    avatarImg(val) {
      return val ? val : "/images/logo.png";
    },
    IMAGES(val) {
      return Help.img(val);
    },
    productSize(val) {
      if (
        val != null &&
        val != undefined &&
        val.stock != null &&
        val.stock != undefined &&
        val.size != null &&
        val.size != undefined &&
        val.size.unit != null &&
        val.size.unit != undefined
      ) {
        if (val.size.unit === "pack") {
          const num = val.stock / val.size.amount;
          return `${val.stock} / ${Number(num).toFixed(1)}`;
        } else {
          return val.stock;
        }
      } else if (
        val != null &&
        val != undefined &&
        val.stock != null &&
        val.stock != undefined &&
        val.product_id != null &&
        val.product_id != undefined &&
        val.product_id.size != null &&
        val.product_id.size != undefined &&
        val.product_id.size.unit != null &&
        val.product_id.size.unit != undefined
      ) {
        if (val.product_id.size.unit === "pack") {
          const num = val.stock / val.product_id.size.amount;
          return `${val.stock} / ${Number(num).toFixed(1)}`;
        } else {
          return val.stock;
        }
      } else {
        return val.stock;
      }
    },

    footerPage(val) {
      const start = val.pageStart;
      const stop = val.pageStop;
      const total = val.itemsLength;
      return `${start > 2 ? start - 2 : start - 1} - ${stop} of ${total}`;
    },
    stockPrice(val) {
      const total_price =
        val
          ?.filter((item) => item?.stock && item.product.price_buy)
          .map((item) => item.stock * item.product.price_buy)
          .reduce((a, b) => a + b, 0) ?? 0;
      return this.$options.filters.NumberFormat(total_price);
    },
    total_discount({ discount_type, total_discount }) {
      if (discount_type === "percentage" && total_discount) {
        return `${total_discount} %`;
      } else {
        return this.$options.filters.NumberFormat(total_discount);
      }
    },
    stockPriceSale(val) {
      const total_price =
        val
          ?.filter((item) => item?.stock && item.product.price_sale)
          .map(
            (item) =>
              (item.product.price_sale - item.product.price_buy) * item.stock
          )
          .reduce((a, b) => a + b, 0) ?? 0;
      return this.$options.filters.NumberFormat(total_price);
    },
  },
};
</script>
