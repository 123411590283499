<template>
  <section>
    <v-card rounded="lg" class="mb-5">
      <v-card-title>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3">
          <v-chip-group
            v-model="status_filter"
            mandatory
            column
            active-class="deep-purple--text text--accent-4"
          >
            <v-chip
              v-for="(val, i) in status_list"
              :key="i"
              :value="val.value"
              filter
              outlined
            >
              {{ $t(val.text) }}
            </v-chip>
          </v-chip-group>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5 offset-lg-4">
          <div class="d-flex align-center">
            <TextSearch
              v-model="search"
              @search="fetchItem()"
              @onClear="
                search = '';
                fetchItem();
              "
            />

            <v-btn class="ml-3" depressed color="primary" @click="newItem">
              <v-icon left>fal fa-plus-circle</v-icon>

              {{ $t("new_data") }}
            </v-btn>
          </div>
        </div>
      </v-card-title>

      <v-card-text>
        <div class="row">
          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-1 offset-lg-10">
            <TextSelect
              v-model="per_page"
              :dense="true"
              :placeholder="false"
              :items="pageList"
            />
          </div>
          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-1 text-right">
            <v-pagination
              v-model="current_page"
              :length="items.last_page"
            ></v-pagination>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <div class="row">
      <div
        class="col-xs-12 col-sm-12 col-md-12 col-lg-2 col-xl-2"
        v-for="(item, index) in items.data"
        :key="index"
      >
        <v-card rounded="lg">
          <v-list-item three-line>
            <v-list-item-content>
              <div class="overline mb-4">
                <v-chip v-if="statusTable(item)" color="primary" small label>
                  {{ $t("new") }}
                </v-chip>
                <v-icon v-else small :color="tableStatusColor(item.status)">
                  {{ iconStatus(item.status) }}
                </v-icon>
              </div>
              <v-list-item-title class="headline mb-1">
                {{ item.name }}
              </v-list-item-title>
              <v-list-item-subtitle>
                <template v-if="item.status === 'reserved'">
                  {{ item.note }}
                </template>
                <template v-else-if="item.status === 'available'">
                  {{ $t("avaiable") }}
                </template>

                <template v-else>
                  <span v-for="(val, index) in item.tickets" :key="index">
                    {{ val.total_price | NumberFormat }}
                    <span v-if="index !== item.tickets.length - 1">,</span>
                  </span>
                </template>
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-avatar size="40">
              <v-btn fab depressed small @click="openPayment(item)">
                <v-icon small>fal fa-receipt</v-icon>
              </v-btn>
            </v-list-item-avatar>
          </v-list-item>

          <v-card-actions>
            <v-spacer />

            <v-btn icon depressed @click="generateQR(item)">
              <v-icon small> fas fa-qrcode</v-icon>
            </v-btn>
            <v-btn
              icon
              depressed
              @click="openTable(item)"
              class="mx-2"
              v-if="item.status === 'available'"
            >
              <v-icon small> fas fa-layer-group </v-icon>
            </v-btn>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon depressed v-bind="attrs" v-on="on">
                  <v-icon small> fas fa-ellipsis-h </v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item @click="viewItem(item)" dense>
                  <v-list-item-icon>
                    <v-icon small> fas fa-eye </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    {{ $t("description") }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="editItem(item)" dense>
                  <v-list-item-icon>
                    <v-icon small> fas fa-edit </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title> {{ $t("edit") }} </v-list-item-title>
                </v-list-item>
                <v-list-item @click="deleteItem(item)" dense>
                  <v-list-item-icon>
                    <v-icon small> fas fa-trash-alt </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title> {{ $t("delete") }} </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card-actions>
        </v-card>
      </div>
    </div>

    <app-custom-dialog
      v-model="dialog"
      :footer="false"
      title="description"
      @submit="submitForm"
    >
      <div slot="activator" class="px-6 py-3">
        <v-row>
          <v-col cols="4">{{ $t("table_id") }}</v-col>
          <v-col cols="8">{{ form.id }}</v-col>
          <v-col cols="4">{{ $t("table_name") }}</v-col>
          <v-col cols="8">{{ form.name }}</v-col>
          <v-col cols="4">{{ $t("status") }}</v-col>
          <v-col cols="8">
            <v-chip
              label
              :color="tableStatusColor(form.status)"
              small
              class="white--text"
            >
              {{ $t(form.status) }}
            </v-chip>
          </v-col>
          <v-col cols="4">{{ $t("description") }}</v-col>
          <v-col cols="8">{{ form.note ?? "..." }}</v-col>
          <v-col cols="4"> {{ $t("item") }} </v-col>
          <v-col cols="8">
            <v-chip
              v-for="(item, index) in form.tickets"
              :key="index"
              color="primary"
              text-color="white"
              class="ma-1"
              small
            >
              {{ item.txn }}
            </v-chip>
          </v-col>
          <v-col cols="4">{{ $t("date") }}</v-col>
          <v-col cols="8">{{ form.created_at | moment }}</v-col>
        </v-row>
      </div>
    </app-custom-dialog>
    <app-custom-dialog
      v-model="dialogAdd"
      :footer="true"
      :title="titleStatus"
      @submit="submitForm"
    >
      <div slot="activator">
        <TTextField
          label="table"
          :errorMessages="nameError"
          v-model="form.name"
          @input="$v.form.name.$touch()"
          @blur="$v.form.name.$touch()"
        />
      </div>
    </app-custom-dialog>
    <app-custom-dialog
      v-model="reservedModal"
      :footer="true"
      :title="statusTitle"
      @submit="status === 'cancel' ? makePayment() : reserved()"
    >
      <div slot="activator">
        <v-row>
          <v-col cols="12">
            <v-textarea
              v-model="note"
              :label="$t('noted')"
              outlined
              color="primary"
              rows="3"
              hide-details="auto"
            ></v-textarea>
          </v-col>
        </v-row>
      </div>
    </app-custom-dialog>
    <app-custom-dialog
      v-model="paymentModal"
      :footer="false"
      title="payment"
      width="400px"
    >
      <div slot="activator">
        <numeric-keyboard
          v-model="inputValue"
          :total_price="total_price"
          @submit="makePayment()"
        />
      </div>
    </app-custom-dialog>

    <app-custom-dialog
      v-model="dialogMoveMerge"
      :title="tableAction"
      :is_translate="false"
      width="1000px"
      @submit="moveMergeTable()"
    >
      <div slot="activator">
        <app-choose-table
          :tables="tables"
          :table_id="table_id"
          :order_id="order_id"
          @setTable="setTable"
          @mergeTable="mergeTable"
        />
      </div>
    </app-custom-dialog>
    <app-custom-dialog
      v-model="show_qr"
      :footer="false"
      :title="tableDetail"
      :is_translate="false"
      width="400px"
    >
      <div slot="activator">
        <div class="text-center">
          <v-img :src="qr" width="200" height="200" class="mx-auto" />
          <v-btn class="mt-3" outlined small @click="copyQR(qr)">
            <v-icon left small> fal fa-copy </v-icon>
            Copy
          </v-btn>
        </div>
      </div>
    </app-custom-dialog>

    <app-custom-dialog
      width="750px"
      v-model="billDetail"
      :footer="false"
      :is_translate="false"
      :title="bill_item"
    >
      <div slot="activator">
        <v-row>
          <v-col cols="12">
            <v-card rounded="lg">
              <v-card-text class="py-0">
                <v-list-item dense class="px-0">
                  <v-list-item-avatar
                    :color="order_table.is_online_order ? 'green' : 'primary'"
                    size="40"
                  >
                    <v-icon small dark>
                      {{
                        order_table.is_online_order
                          ? "fal fa-globe"
                          : "fal fa-store"
                      }}
                    </v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      >{{ order_table.code }} |
                      <span
                        :class="orderStatusColor(order_table.status) + '--text'"
                      >
                        {{ $t(order_table.status) }}
                      </span>
                    </v-list-item-title>
                    <v-list-item-subtitle
                      >{{ order_table.created_at | moment }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action
                    class="font-weight-bold text-h4 primary--text"
                  >
                    {{ order_table.total_price | NumberFormat }}
                  </v-list-item-action>
                </v-list-item>
                <v-divider class="ma-0" />

                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">{{ $t("id") }}</th>
                        <th class="text-left">{{ $t("item") }}</th>
                        <th class="text-center">{{ $t("quantity") }}</th>
                        <th class="text-right">{{ $t("price") }}</th>
                        <th class="text-right">{{ $t("total") }}</th>
                        <th class="text-right">{{ $t("status") }}</th>
                        <th class="text-right">{{ $t("manage") }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(data, index) in order_table.history"
                        :key="index"
                      >
                        <td class="text-left">{{ index + 1 }}</td>
                        <td class="text-left">{{ data.product_id.name }}</td>
                        <td class="text-center">{{ data.qty }}</td>
                        <td class="text-right">
                          {{ data.price_sale | NumberFormat }}
                        </td>
                        <td class="text-right">
                          {{ data.total_price | NumberFormat }}
                        </td>

                        <td
                          class="text-right"
                          :class="orderStatusColor(data.status) + '--text'"
                        >
                          {{ $t(data.status) }}
                        </td>
                        <td class="text-right">
                          <v-menu offset-y>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                depressed
                                v-bind="attrs"
                                v-on="on"
                                small
                                color="primary"
                              >
                                <v-icon small> fas fa-cog</v-icon>
                              </v-btn>
                            </template>
                            <v-list dense>
                              <v-list-item
                                v-for="(val, i) in actionList"
                                :key="i"
                                @click="updateStatusItem(data, val.value)"
                                dense
                              >
                                <v-list-item-icon>
                                  <v-icon small :color="val.color">
                                    {{ val.icon }}
                                  </v-icon>
                                </v-list-item-icon>
                                <v-list-item-title>
                                  {{ $t(val.text) }}
                                </v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
              <v-card-actions>
                <AppAction
                  :item="order_table"
                  :items="actionList"
                  @onConfirm="onConfirm"
                  @checkBill="checkBill"
                  @moveMerge="fetchTable"
                />
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </app-custom-dialog>

    <!-- AppLoading -->
    <app-loading :overlay="overlay" />

    <!-- print -->
    <AppPrint v-show="print" :item="printBill" />
  </section>
</template>

<script>
import PubNub from "pubnub";
import TTextField from "@/components/global/TextField";
import { TABLE_VALIDATE } from "@/validations/shop";
import { success, toast, confirm, successSale } from "@/controllers/alert";
import { myMixin } from "@/mixin/main";
import TextSelect from "@/components/global/TextSelect";
import NumericKeyboard from "@/components/NumericKeyboard";
import { mapGetters, mapActions } from "vuex";
import AppPrint from "@/components/print/restaurant";
import { tableStatusList, pubnubConfig, ActionList } from "@/utils/mock";
import AppAction from "@/components/tables/action";
import AppChooseTable from "@/components/restaurant/chooseTable";

export default {
  mixins: [TABLE_VALIDATE, myMixin],
  data: () => ({
    pubnub: new PubNub(pubnubConfig()),
    show_qr: false,
    qr: "",
    dialogMoveMerge: false,
    print: false,
    update: false,
    dialogAdd: false,
    dialog: false,
    reservedModal: false,
    paymentModal: false,
    billDetail: false,
    inputValue: "",
    search: "",
    note: "",
    table_id: "",
    form: {},
    total_price: 0,
    pageList: [30, 50, 100],
    status: "",
    status_filter: "",
    ticket_id: "",
    status_list: tableStatusList(),
    current_page: 1,
    per_page: 50,
    items: {
      data: [],
      last_page: 0,
    },
    printBill: {},
    actionList: ActionList(),
    order_table: {},
    activeTable: {
      table: {
        name: "",
      },
    },
    table_id: "",
    order_id: "",
    type: "move",
  }),
  async created() {
    await this.fetchItem();
  },
  mounted() {
    this.pubnub.addListener({
      message: (event) => {
        toast(this, event.message, "success");
        Help.playAudio();
        this.fetchItem();
      },
    });
    const channel = `${this.user.stores.code}_waiter`;
    this.pubnub.subscribe({ channels: [channel] });
  },

  watch: {
    per_page() {
      this.fetchItem();
    },
    current_page() {
      this.fetchItem();
    },
    status_filter() {
      this.fetchItem();
    },
  },
  components: {
    TextSelect,
    TTextField,
    NumericKeyboard,
    AppPrint,
    AppAction,
    AppChooseTable,
  },

  computed: {
    ...mapGetters({
      overlay: "index/overlay",
      tables: "index/tables",
    }),
    titleStatus() {
      return this.update ? "update" : "new_data";
    },
    bill_item() {
      const msg = this.$t("bill_item");
      if (this.order_table && this.order_table.table?.name) {
        return msg + " " + this.order_table.table?.name;
      }
      return msg;
    },

    statusTitle() {
      if (this.status === "cancel") {
        return "reason_cancel";
      }
      return "choose_table_for_order";
    },

    tableDetail() {
      return this.$t("table") + " " + this.form.name;
    },

    tableAction() {
      const msg = this.order_table ? "merge_table" : "move_table";
      return this.$t(msg) + " " + this.activeTable.table.name;
    },
  },
  methods: {
    ...mapActions({
      fetch: "index/fetch",
    }),

    async fetchItem() {
      try {
        const params = `per_page=${this.per_page}&page=${this.current_page}&q=${this.search}&status=${this.status_filter}`;
        const { data } = await this.$http.get(`store_table_page?${params}`);
        this.items = data.data;
      } catch (error) {
        console.log("error", error);
      }
    },

    async newItem() {
      await Help.clearForm(this.form, this);
      this.update = false;
      this.dialogAdd = !this.dialogAdd;
    },

    editItem(item) {
      this.update = true;
      this.form = item;
      this.dialogAdd = !this.dialogAdd;
    },
    async deleteItem({ id }) {
      try {
        const { isConfirmed } = await confirm({
          props: this,
          text: "want_to_delete",
        });
        if (isConfirmed) {
          const { data } = await this.$http.delete(`store_table/${id}`);
          if (data.status) {
            await Help.clearForm(this.form, this);
            await this.fetchItem();
            await success(this, data.message);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    viewItem(item) {
      this.form = item;
      this.dialog = !this.dialog;
    },
    async submitForm() {
      try {
        this.$v.form.$touch();
        if (!this.$v.form.$invalid) {
          if (this.update) {
            const { data } = await this.$http.put(
              `store_table/${this.form.id}`,
              this.form
            );
            if (data.status) {
              this.dialogAdd = !this.dialogAdd;
              await Help.clearForm(this.form, this);
              await this.fetchItem();
              await success(this, data.message);
            }
          } else {
            const { data } = await this.$http.post("store_table", this.form);
            if (data.status) {
              this.dialogAdd = !this.dialogAdd;
              await Help.clearForm(this.form, this);
              await this.fetchItem();
              await success(this, data.message);
            }
          }
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    openTable({ id }) {
      this.reservedModal = !this.reservedModal;
      this.table_id = id;
    },
    async reserved() {
      try {
        const body = {
          table_id: this.table_id,
          note: this.note,
        };
        const { data } = await this.$http.post("reserved", body);
        if (data.status) {
          await Help.clearForm(this.form, this);
          this.reservedModal = !this.reservedModal;
          this.note = "";
          await this.fetchItem();
          await success(this, data.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async openPayment(val) {
      try {
        if (val.tickets.length === 0) return;
        // loop and update the tickets .is_new_order to 0
        val.tickets.forEach(async (val) => {
          val.is_new_order = 0;
        });
        const { data } = await this.$http.get(`get_table_order/${val.id}`);
        this.inputValue = "";
        this.order_table = data.data;
        this.billDetail = !this.billDetail;
      } catch (error) {
        console.log(error);
      }
    },
    async checkBill(status, ticket_id, total) {
      this.ticket_id = ticket_id;
      this.status = status;
      this.total_price = total;
      if (status === "cancel") {
        this.billDetail = false;
        this.note = "";
        this.reservedModal = true;
      } else {
        this.inputValue = total.toString();
        this.billDetail = false;
        this.paymentModal = true;
      }
    },
    async makePayment() {
      try {
        if (this.status === "cancel" && !this.note) {
          // if note is empty
          return toast(this, "note_required", "error");
        }
        const body = {
          status: this.status,
          ticket_id: this.ticket_id,
          note: this.note,
          paid: this.inputValue,
        };
        // check status is done compare input value and total
        const { status, total, message } = await Help.comparePrice(
          this,
          this.inputValue,
          this.total_price
        );
        if (this.status === "done" && !status) {
          return toast(this, message, "error");
        }
        const { data } = await this.$http.post("check_out", body);
        if (data.status) {
          this.paymentModal = false;
          this.inputValue = "";
          this.note = "";
          this.ticket_id = "";
          this.status = "";
          this.reservedModal = false;
          this.billDetail = false;
          await Help.clearForm(this.form, this);
          await this.fetchItem();
          const mst_tran = this.$t("order_cancel_success");
          const mgs = data.data.status === "done" ? message : mst_tran;
          const { isConfirmed } = await successSale(this, total, mgs);
          if (isConfirmed) {
            const object = await Help.mergeProduct(data.data);
            this.printBill = object;
            setTimeout(() => {
              this.$htmlToPaper("restaurantPrint");
            }, 500);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    generateQR(val) {
      this.form = val;
      const code = this.user.stores.code;
      const branch_id = val.branch_id;
      const params = `${code}/${branch_id}/${val.id}`;
      const qr = this.StringToQRCode(params);
      this.qr = qr;
      this.show_qr = !this.show_qr;
    },
    // onConfirm before update status
    async onConfirm(id, status, total) {
      try {
        if (status === "cancel") {
          this.status = status;
          this.ticket_id = id;
          this.total_price = total;
          this.inputValue = total;
          this.billDetail = false;
          this.note = "";
          this.reservedModal = true;
          return;
        }
        const { isConfirmed } = await confirm({
          props: this,
        });
        if (isConfirmed) {
          await this.updateStatusBill(id, status);
        }
      } catch (error) {
        console.log(error);
      }
    },
    /* --------------------------- status header bill --------------------------- */
    async updateStatusBill(id, status) {
      try {
        const body = { status };
        const { data } = await this.$http.put(`update_status_bill/${id}`, body);
        if (data.status) {
          // loop find the bill id and loop of items in bill and update the status
          this.order_table = data.data;
          await this.fetchItem();
          await success(this, "update_status_success");
        }
      } catch (error) {
        console.log(error);
      }
    },
    /* ---------------------- each item in item.tickets --------------------- */
    async updateStatusItem(val, status) {
      try {
        const body = { status };
        const { data } = await this.$http.put(
          `update_status_item/${val.id}`,
          body
        );
        if (data.status) {
          this.$set(val, "status", status);
          // if status is 'cancel' then reduce the total price of bill by find the bill id
          if (status === "cancel") {
          }
          await this.fetchItem();
          toast(this, data.message, "success");
        }
      } catch (error) {
        console.log(error);
      }
    },
    statusTable(object) {
      // if the object is not empty check if tickets status is 'pending' then return true
      if (Object.keys(object).length > 0) {
        return object.tickets.some((val) => val.is_new_order);
      }
      return false;
    },
    async fetchTable(item) {
      this.activeTable = item;
      const body = {
        url: `store_table?not_in=${item.table_id}`,
        that: this,
        commit: "SET_TABLES",
      };
      await this.fetch(body);
      this.billDetail = false;
      this.dialogMoveMerge = true;
    },

    async moveMergeTable() {
      try {
        const body = {
          type: this.type,
          from_table_id: this.activeTable.table_id,
          from_ticket_id: this.activeTable.id,
          to_table_id: this.table_id,
          to_ticket_id: this.order_table?.id || null,
        };
        const { data } = await this.$http.post("move_merge_table", body);
        if (data.status) {
          // fetch table
          await this.fetchItem();
          this.dialogMoveMerge = false;
          await success(this, data.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
    async setTable(id) {
      try {
        this.table_id = id;
        const { data } = await this.$http.get(`get_table_order/${id}?check=0`);
        if (data.status) {
          // if data.data is not empty then set the data to order_table
          if (data.data) {
            this.type = "merge";
            this.order_table = data.data;
          } else {
            this.type = "move";
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async mergeTable(id) {
      if (id === this.order_id) {
        this.order_id = "";
        return;
      }
      this.order_id = id;
    },
  },
};
</script>

<style scoped>
/* Modern styling for restaurant table management */
/* Add this style block to your component */

/* Filter Card */
.v-card {
  border: none !important;
  overflow: hidden;
  transition: all 0.3s ease;
  border-radius: 12px !important;
}

.v-card.rounded-lg {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05) !important;
}

.v-card-title {
  padding-top: 20px;
  padding-bottom: 12px;
}

/* Status Chips */
.v-chip.v-chip {
  font-weight: 500 !important;
  border-radius: 20px !important;
  transition: all 0.2s ease;
  box-shadow: none !important;
  border: 1px solid #e0e0e0 !important;
  height: 32px;
}

.v-chip.v-chip--active {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1) !important;
  transform: translateY(-1px);
}

/* New Data Button */
.v-btn.v-btn--depressed.v-btn--has-bg {
  border-radius: 8px !important;
  text-transform: none !important;
  font-weight: 600 !important;
  letter-spacing: 0 !important;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1) !important;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.v-btn.v-btn--depressed.v-btn--has-bg:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15) !important;
}

/* Table Cards */
.row > .col-xs-12.col-sm-12.col-md-12.col-lg-2.col-xl-2 {
  padding: 12px;
}

.headline {
  font-size: 22px !important;
  font-weight: 700 !important;
  color: #333;
  margin-bottom: 8px !important;
}

.v-list-item__subtitle {
  color: #666 !important;
  font-size: 14px !important;
  white-space: normal !important;
  margin-top: 4px;
}

.v-list-item__content {
  padding-bottom: 8px;
}

.overline {
  margin-bottom: 8px;
}

/* Table status styling */
.v-card .v-list-item__avatar .v-btn {
  background-color: #f3f4f6 !important;
  color: #4a5568 !important;
  transition: all 0.2s ease;
  box-shadow: none !important;
}

.v-card .v-list-item__avatar .v-btn:hover {
  background-color: #e2e8f0 !important;
  transform: scale(1.1);
}

/* Action buttons styling */
.v-card-actions .v-btn {
  margin: 0 3px !important;
  background-color: #f3f4f6 !important;
  transition: all 0.2s ease !important;
  opacity: 0.8;
}

.v-card-actions .v-btn:hover {
  background-color: #e2e8f0 !important;
  opacity: 1;
  transform: scale(1.1);
}

/* Status-based styling for action buttons */
.v-card-actions .v-btn:first-child:hover {
  color: #9c27b0 !important; /* QR button */
}

.v-card-actions .v-btn:nth-child(2):hover {
  color: #2196f3 !important; /* Layer button */
}

.v-card-actions .v-btn:last-child:hover {
  color: #607d8b !important; /* Menu button */
}

/* Dropdown menu */
.v-list.v-sheet {
  border-radius: 8px !important;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15) !important;
}

.v-list-item {
  transition: background-color 0.2s ease;
}

.v-list-item:hover {
  background-color: #f5f5f5 !important;
}

/* Dynamic left border colors based on table status */
.v-card {
  position: relative;
  border-left: 4px solid transparent !important;
}

/* Update these selectors to match your iconStatus or tableStatusColor logic */
/* For available status - green */
.v-card .v-icon.success--text,
.v-card .v-icon.green--text {
  color: #4caf50 !important;
}
.v-card:has(.v-icon.success--text),
.v-card:has(.v-icon.green--text) {
  border-left-color: #4caf50 !important;
}

/* For reserved status - orange/amber */
.v-card .v-icon.warning--text,
.v-card .v-icon.amber--text {
  color: #ffc107 !important;
}
.v-card:has(.v-icon.warning--text),
.v-card:has(.v-icon.amber--text) {
  border-left-color: #ffc107 !important;
}

/* For busy/occupied status - red */
.v-card .v-icon.error--text,
.v-card .v-icon.red--text {
  color: #f44336 !important;
}
.v-card:has(.v-icon.error--text),
.v-card:has(.v-icon.red--text) {
  border-left-color: #f44336 !important;
}

/* For in-progress/pending status - blue */
.v-card .v-icon.info--text,
.v-card .v-icon.blue--text {
  color: #2196f3 !important;
}
.v-card:has(.v-icon.info--text),
.v-card:has(.v-icon.blue--text) {
  border-left-color: #2196f3 !important;
}

/* For new orders - primary color */
.v-card:has(.v-chip.primary) {
  border-left-color: var(--v-primary-base, #1976d2) !important;
}

/* Alternative approach if :has() selector isn't supported in your browser */
/* You'll need to add appropriate classes to your cards */
.v-card.status-available {
  border-left-color: #4caf50 !important;
}

.v-card.status-reserved {
  border-left-color: #ffc107 !important;
}

.v-card.status-busy {
  border-left-color: #f44336 !important;
}

.v-card.status-pending {
  border-left-color: #2196f3 !important;
}

/* Card hover effects */
.v-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.v-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1) !important;
}

/* Responsive adjustments */
@media (max-width: 960px) {
  .row > .col-xs-12.col-sm-12.col-md-12.col-lg-2.col-xl-2 {
    padding: 8px;
  }

  .headline {
    font-size: 18px !important;
  }
}
</style>
