<template>
  <v-card flat class="pa-4 purchase-details">
    <!-- Purchase Header Section -->
    <v-card outlined rounded="lg" class="mb-4">
      <v-card-title class="primary--text">
        {{ $t("purchase_details") }}
        <v-spacer></v-spacer>
        <v-chip
          color="primary"
          dark
          label
          small
          class="font-weight-bold purchase-code"
        >
          {{ item.code }}
        </v-chip>
        <v-btn
          fab
          class="rounded-lg ml-2"
          depressed
          x-small
          @click="copyToClipboard(item.code)"
          title="Copy purchase number"
        >
          <v-icon x-small>fal fa-copy</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider class="my-0" />

      <v-card-text>
        <v-row>
          <!-- Purchase Information -->
          <v-col cols="12" md="7" lg="8">
            <v-row class="info-grid">
              <v-col cols="12" sm="6">
                <v-row dense>
                  <v-col cols="5" class="info-label">
                    {{ $t("branch") }}:
                  </v-col>
                  <v-col cols="7" class="font-weight-medium">
                    {{ item.branch ? item.branch.name : "-" }}
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="5" class="info-label">
                    {{ $t("maker") }}:
                  </v-col>
                  <v-col cols="7" class="font-weight-medium">
                    {{ item.user ? item.user.first_name : "-" }}
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col cols="5" class="info-label">
                    {{ $t("product_item") }}:
                  </v-col>
                  <v-col cols="7" class="font-weight-medium">
                    {{ item.stock_history_count }} {{ $t("item") }}
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" sm="6">
                <v-row dense>
                  <v-col cols="5" class="info-label">
                    {{ $t("created_at") }}:
                  </v-col>
                  <v-col cols="7" class="font-weight-medium">
                    {{ item.created_at | moment }}
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="5" class="info-label">
                    {{ $t("profit_percent") }}:
                  </v-col>
                  <v-col cols="7" class="font-weight-medium">
                    {{ total_summary.percent | NumberFormat }}%
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="5" class="info-label">
                    {{ $t("status") }}:
                  </v-col>
                  <v-col cols="7">
                    <v-chip
                      x-small
                      :color="getStatusColor(item.status)"
                      text-color="white"
                      class="font-weight-medium"
                    >
                      {{ $t(item.status) }}
                    </v-chip>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>

          <!-- Summary Card -->
          <v-col cols="12" md="5" lg="4">
            <v-card outlined class="summary-card">
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="summary-item">
                    <div class="summary-label">{{ $t("total_buy") }}</div>
                    <div class="summary-value red--text">
                      {{ total_summary.price_buy | NumberFormat }}
                    </div>
                  </div>

                  <div class="summary-item">
                    <div class="summary-label">{{ $t("total_sale") }}</div>
                    <div class="summary-value primary--text">
                      {{ total_summary.price_sale | NumberFormat }}
                    </div>
                  </div>

                  <div class="summary-item">
                    <div class="summary-label">{{ $t("total_profit") }}</div>
                    <div class="summary-value green--text font-weight-bold">
                      {{ total_summary.profit | NumberFormat }}
                    </div>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- Items Section -->
    <v-card outlined rounded="lg">
      <v-card-title class="items-title">
        {{ $t("purchased_items") }}
        <v-spacer></v-spacer>
      </v-card-title>

      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th scope="col">{{ $t("no") }}</th>
              <th scope="col">{{ $t("image") }}</th>
              <th scope="col">{{ $t("name") }}</th>
              <th scope="col">{{ $t("unit") }}</th>
              <th scope="col">{{ $t("currency") }}</th>
              <th scope="col">{{ $t("exchange_rate") }}</th>
              <th scope="col">{{ $t("price_buy") }}</th>
              <th scope="col">{{ $t("quantity") }}</th>
              <th scope="col">{{ $t("price_sale") }}</th>
              <th scope="col">{{ $t("action") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(data, index) in item.history" :key="index">
              <td>{{ index + 1 }}.</td>
              <td>
                <v-avatar
                  tile
                  size="40"
                  class="rounded-lg"
                  color="grey lighten-2"
                >
                  <app-img :img="data.product.img" />
                </v-avatar>
              </td>
              <td>{{ data.product.name | nameLimit }}</td>
              <td>{{ data.product.size.name }}</td>
              <td>{{ data.currency.code }}</td>
              <td>{{ data.exchange_rate | NumberFormat }}</td>
              <td>
                {{ (data.price_buy / data.exchange_rate) | NumberFormat }}
              </td>
              <!-- <td>{{ data.last_stock | NumberFormat }}</td> -->
              <td>{{ data.qty | NumberFormat }}</td>
              <!-- <td>
                {{ data.current_stock | NumberFormat }}
              </td> -->
              <td>
                {{ (data.price_sale / data.exchange_rate) | NumberFormat }}
              </td>
              <td class="text-right">
                <v-btn
                  fab
                  depressed
                  x-small
                  class="rounded-lg"
                  @click="viewItem(data)"
                >
                  <v-icon color="blue" x-small>fal fa-eye</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>

    <div class="currency-breakdown pt-0">
      <div class="breakdown-title">{{ $t("currency_breakdown") }}</div>
      <div class="breakdown-grid">
        <div
          v-for="(summary, currency) in currencySummary"
          :key="currency"
          class="breakdown-card"
        >
          <div class="breakdown-header">{{ currency }}</div>
          <div class="breakdown-content">
            <div class="breakdown-row">
              <span>{{ $t("quantity") }}:</span>
              <span>{{ summary.qty | NumberFormat }}</span>
            </div>
            <div class="breakdown-row">
              <span>{{ $t("price_buy") }}:</span>
              <span class="red--text">{{
                summary.price_buy | NumberFormat
              }}</span>
            </div>
            <div class="breakdown-row">
              <span>{{ $t("price_sale") }}:</span>
              <span class="primary--text">{{
                summary.price_sale | NumberFormat
              }}</span>
            </div>
            <div class="breakdown-row">
              <span>{{ $t("profit") }}:</span>
              <span :class="summary.profit > 0 ? 'green--text' : 'red--text'">
                {{ summary.profit | NumberFormat }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import { myMixin } from "@/mixin/main";

export default {
  mixins: [myMixin],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      search: "",
      loading: false,
      headers: [
        { text: this.$t("no"), value: "index", align: "center", width: "50px" },
        {
          text: this.$t("image"),
          value: "image",
          align: "center",
          sortable: false,
        },
        { text: this.$t("product"), value: "product.name", align: "left" },
        {
          text: this.$t("unit"),
          value: "product.size.name",
          align: "center",
        },
        {
          text: this.$t("currency"),
          value: "currency.code",
          align: "center",
        },
        {
          text: this.$t("exchange_rate"),
          value: "exchange_rate",
          align: "right",
        },
        {
          text: this.$t("price_buy"),
          value: "price_buy",
          align: "right",
        },
        {
          text: this.$t("price_sale"),
          value: "price_sale",
          align: "right",
        },
        {
          text: this.$t("quantity"),
          value: "qty",
          align: "center",
          width: "80px",
        },
        {
          text: this.$t("total_buy"),
          value: "total_buy",
          align: "right",
        },
        {
          text: this.$t("total_sale"),
          value: "total_sale",
          align: "right",
        },
        {
          text: this.$t("profit"),
          value: "profit",
          align: "right",
          width: "100px",
        },
        {
          text: this.$t("action"),
          value: "actions",
          align: "center",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    total_summary() {
      // loop through desserts and calculate total summary
      const summary = {
        price_buy: 0,
        price_sale: 0,
        profit: 0,
        qty: 0,
        percent: 0,
      };
      if (this.item && this.item.history) {
        this.item.history.forEach((item) => {
          const rate = item.exchange_rate;
          const price_buy = item.price_buy / rate;
          const price_sale = item.price_sale / rate;
          summary.price_buy += price_buy * item.qty * rate;
          summary.price_sale += price_sale * item.qty * rate;
          summary.qty += parseFloat(item.qty);
          summary.profit += (price_sale - price_buy) * item.qty * rate;
          summary.percent = (
            (summary.profit / summary.price_buy) *
            100
          ).toFixed(2);
        });
      }

      return summary;
    },

    profitPercentage() {
      if (this._totalBuy === 0) return 0;
      return ((this._totalProfit / this._totalBuy) * 100).toFixed(2);
    },
    profitPercentageColor() {
      const percentage = this.profitPercentage;
      if (percentage > 20) return "green--text";
      if (percentage > 0) return "light-green--text";
      if (percentage < 0) return "red--text";
      return "grey--text";
    },
    currencySummary() {
      const summary = {};

      if (this.item && this.item.history) {
        this.item.history.forEach((item) => {
          const currency = item.currency.code;
          const rate = item.exchange_rate;
          const price_buy = item.price_buy / rate;
          const price_sale = item.price_sale / rate;

          if (!summary[currency]) {
            summary[currency] = {
              price_buy: 0,
              price_sale: 0,
              qty: 0,
              profit: 0,
            };
          }

          summary[currency].qty += parseFloat(item.qty);
          summary[currency].price_buy += price_buy * item.qty;
          summary[currency].price_sale += price_sale * item.qty;
          summary[currency].profit += (price_sale - price_buy) * item.qty;
        });
      }

      return summary;
    },
  },
  methods: {
    viewItem(data) {
      this.$emit("viewItem", data);
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(
        () => {
          this.$toast.success(this.$t("copied_to_clipboard"));
        },
        () => {
          this.$toast.error(this.$t("copy_failed"));
        }
      );
    },
    getStatusColor(status) {
      const statusColors = {
        pending: "orange",
        completed: "success",
        cancelled: "error",
        processing: "primary",
      };
      return statusColors[status] || "grey";
    },
    getItemProfit(item) {
      const buyPrice = item.price_buy / item.exchange_rate;
      const salePrice = item.price_sale / item.exchange_rate;
      return (salePrice - buyPrice) * item.qty;
    },
    formatDate(dateString) {
      if (!dateString) return "-";
      const date = new Date(dateString);
      return date.toLocaleDateString();
    },
  },
  filters: {
    formatDate(dateString) {
      if (!dateString) return "-";
      const date = new Date(dateString);
      return date.toLocaleDateString();
    },
    nameLimit(name) {
      if (!name) return "";
      return name.length > 30 ? name.substring(0, 30) + "..." : name;
    },
  },
};
</script>

<style scoped>
.purchase-details {
  /* Main container styles */
  background-color: #f8f9fa;
}

.purchase-code {
  font-family: monospace;
  letter-spacing: 0.5px;
}

.info-grid {
  margin-top: 8px;
}

.info-label {
  color: rgba(0, 0, 0, 0.6);
  font-weight: 500;
}

/* Summary card styling */
.summary-card {
  background-color: #f5f7fa;
  border-radius: 8px;
  height: 100%;
}

.summary-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  border-bottom: 1px dashed #e0e0e0;
}

.summary-label {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.7);
}

.summary-value {
  font-size: 1.1rem;
  font-weight: 600;
}

/* Table styling */
.items-title {
  display: flex;
  align-items: center;
}

.search-field {
  max-width: 250px;
}

.items-table {
  border-radius: 0 0 8px 8px;
}

.product-name {
  font-weight: 500;
}

.product-code {
  font-family: monospace;
}

.actions-cell {
  display: flex;
  justify-content: center;
}

/* Currency breakdown section */
.currency-breakdown {
  background-color: #f5f7fa;
  margin-top: 16px;
  border-radius: 8px;
}

.breakdown-title {
  font-size: 1.1rem;
  font-weight: 500;
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.8);
}

.breakdown-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.breakdown-card {
  flex: 1 1 250px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.breakdown-header {
  background-color: #1976d2;
  color: white;
  padding: 8px 12px;
  font-weight: 500;
}

.breakdown-content {
  padding: 12px;
}

.breakdown-row {
  display: flex;
  justify-content: space-between;
  padding: 4px 0;
}

@media (max-width: 960px) {
  .breakdown-card {
    flex: 1 1 100%;
  }
}
</style>
