<template>
  <section id="quotationPrint" v-if="Object.keys(item).length > 0">
    <div class="container quotationPrint">
      <v-row>
        <v-col cols="8">
          <v-row align="center" justify="center">
            <v-col cols="3">
              <img v-if="Img" width="70" :src="Img" alt="logo" class="logo" />
            </v-col>
            <v-col cols="9">
              <p class="title">{{ user.stores.name }}</p>
              <p>
                <v-icon small color="#000000" left>
                  fal fa-map-marker-alt
                </v-icon>
                {{ user.stores.address }}
              </p>
              <p>
                <v-icon small color="#000000" left> fal fa-phone </v-icon>
                {{ user.stores.phone_no }}
              </p>
              <p>
                <v-icon small color="#000000" left> fal fa-envelope </v-icon>
                {{ user.email }}
              </p>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4" class="text-right">
          <h1 class="quotation-title">{{ documents }}</h1>
        </v-col>
      </v-row>
      <v-divider></v-divider>

      <div class="address-block">
        <div class="address">
          <h3>{{ document_to }}</h3>
          <p>
            <v-icon small color="#000000" left> fal fa-user </v-icon>
            {{ item.customer.first_name }}
          </p>
          <p>
            <v-icon small color="#000000" left> fal fa-map-marker-alt </v-icon>
            {{ item.customer.address }}
          </p>
          <p>
            <v-icon small color="#000000" left> fal fa-phone </v-icon>
            {{ item.customer.phone_no }}
          </p>
        </div>
        <div class="address text-right">
          <h3>{{ documents_info }}</h3>
          <v-row>
            <v-col cols="4"> </v-col>
            <v-col cols="8" class="text-left">
              <v-row>
                <v-col cols="7">
                  <v-icon small color="#000000" left> fal fa-file-alt </v-icon>
                  {{ document_no }}
                </v-col>
                <v-col cols="5" class="text-right pb-0">
                  {{ item.quotation_no }}
                </v-col>

                <v-col cols="7">
                  <v-icon small color="#000000" left> fal fa-file-alt </v-icon>
                  {{ $t("issue_date") }}
                </v-col>
                <v-col cols="5" class="text-right pb-0">
                  {{ item.created_at | created_at }}
                </v-col>
                <template v-if="item.document === 'quotation'">
                  <v-col cols="7">
                    <v-icon small color="#000000" left>
                      fal fa-file-alt
                    </v-icon>
                    {{ $t("expiry_date") }}
                  </v-col>
                  <v-col cols="5" class="text-right pb-0">
                    {{ item.expiry_date | created_at }}
                  </v-col>
                </template>
              </v-row>
            </v-col>
          </v-row>
        </div>
        <!-- <div class="address">
          <h3>{{ $t("exchange_rate") }}</h3>
          <p>LAKTHB {{ item.store.thb | NumberFormat }}</p>
          <p>LAKUSD {{ item.store.usd | NumberFormat }}</p>
        </div> -->
      </div>
      <div class="text-right text-uppercase mb-2">
        <p>{{ $t("currency") }} : {{ item.currency }}</p>
      </div>
      <table>
        <tr>
          <th>{{ $t("no") }}</th>
          <th>{{ $t("name") }}</th>
          <th>{{ $t("category") }}</th>
          <th>{{ $t("unit") }}</th>
          <th>{{ $t("price") }}</th>
          <th>{{ $t("quantity") }}</th>
          <th>{{ $t("total") }}</th>
        </tr>
        <tr v-for="(item, index) in item.quotation_details" :key="index">
          <td>{{ index + 1 }}</td>
          <td>{{ item.product.name }}</td>
          <td>{{ item.product.category.la_name }}</td>
          <td>{{ item.product.size.name }}</td>
          <td>{{ item.unit_price | NumberFormat }}</td>
          <td>{{ item.quantity | NumberFormat }}</td>
          <td>{{ item.total_price | NumberFormat }}</td>
        </tr>
      </table>

      <div class="total">
        <p>{{ $t("amount_paid") }} : {{ item.total_amount | NumberFormat }}</p>
        <p v-if="item.include_tax">
          VAT {{ tax }} : {{ item.tax_amount | NumberFormat }}
        </p>
        <!-- <template v-if="item.document === 'quotation'">
          <p>{{ $t("total") }} THB : {{ thRate }}</p>
          <p>{{ $t("total") }} USD : {{ usRate }}</p>
        </template> -->
        <p>
          <strong
            >{{ $t("grand_sub_total") }}
            <span class="text-uppercase"> ({{ item.currency }}) </span>
            :
            {{ item.grand_total | NumberFormat }}
          </strong>
        </p>
      </div>

      <div class="notes py-3">
        <h3>{{ $t("additional_note") }}</h3>
        <div v-html="item.noted" />
      </div>
      <v-row>
        <v-col cols="6">
          {{ $t("bank_account") }}
          <p v-for="(account, index) in accounts" :key="index">
            {{ account.bank_name }} :
            {{ account.account_no }}
            {{ account.account_name }}
            ({{ account.currency }})
          </p>
        </v-col>
        <v-col cols="6">
          <div class="signature">
            <p>{{ $t("authorized_signature") }}</p>
          </div>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
import { myMixin } from "@/mixin/main";

export default {
  mixins: [myMixin],
  props: {
    item: {
      type: Object,
    },
    accounts: {
      type: Array,
    },
  },
  computed: {
    Img() {
      if (this.user && this.user.stores && this.user.stores.logo) {
        // check logo is contain default string or not
        if (!this.user.stores.logo.includes("default")) {
          return Help.img(this.user.stores.logo);
        }
      }
    },
    thRate() {
      if (this.item && this.item.store && this.item.store.thb) {
        const thb = this.item.store.thb;
        const total = this.item.grand_total;
        return this.$options.filters.NumberFormat(total / thb);
      }
      return 0;
    },
    usRate() {
      if (this.item && this.item.store && this.item.store.usd) {
        const usd = this.item.store.usd;
        const total = this.item.grand_total;
        return this.$options.filters.NumberFormat(total / usd);
      }
      return 0;
    },
    documents() {
      if (this.item && this.item.document) {
        const doc = this.item.document;
        // if doc === quotation
        if (doc === "quotation") {
          return this.$t("quotation_title");
        } else {
          return this.$t("invoice_title");
        }
      }
    },
    document_by() {
      if (this.item && this.item.document) {
        const doc = this.item.document;
        // if doc === quotation
        if (doc === "quotation") {
          return this.$t("quotation_by");
        } else {
          return this.$t("invoice_by");
        }
      }
    },
    document_to() {
      if (this.item && this.item.document) {
        const doc = this.item.document;
        // if doc === quotation
        if (doc === "quotation") {
          return this.$t("quotation_to");
        } else {
          return this.$t("invoice_to");
        }
      }
    },
    documents_info() {
      if (this.item && this.item.document) {
        const doc = this.item.document;
        // if doc === quotation
        if (doc === "quotation") {
          return this.$t("quotations_info");
        } else {
          return this.$t("invoice_info");
        }
      }
    },
    document_no() {
      if (this.item && this.item.document) {
        const doc = this.item.document;
        // if doc === quotation
        if (doc === "quotation") {
          return this.$t("quotation_no");
        } else {
          return this.$t("invoice_no");
        }
      }
    },
  },
};
</script>
