<template>
  <section>
    <v-card rounded="lg">
      <v-card-title class="primary--text">
        {{ $t("sort_product") }}
      </v-card-title>
      <v-card-text>
        <main class="page page--table">
          <v-data-table
            :headers="headers"
            :items="sortProducts.data"
            :items-per-page="options.itemsPerPage"
            :loading="loading"
            item-key="id"
            :show-select="false"
            hide-default-footer
            fixed-header
            class="page__table"
          >
            <template v-slot:body="props">
              <draggable :list="props.items" tag="tbody" @change="handleChange">
                <tr v-for="(item, index) in sortProducts.data" :key="index">
                  <td>{{ item.number }}</td>
                  <td>{{ item.code }}</td>
                  <td>
                    <v-avatar
                      tile
                      size="40"
                      class="rounded-lg"
                      color="grey lighten-2"
                    >
                      <app-img :img="item.img" />
                    </v-avatar>
                  </td>
                  <td>{{ item.name }}</td>
                  <td>{{ item.stock }}</td>
                  <td>
                    {{ item.history_sum_qty ? item.history_sum_qty : 0 }}
                  </td>
                  <td>
                    {{
                      $i18n.locale === "en"
                        ? item.category.en_name
                        : item.category.la_name
                    }}
                  </td>

                  <td>
                    <v-chip
                      label
                      dark
                      :color="
                        item.size.unit === 'amount'
                          ? 'orange accent-2'
                          : 'green accent-3'
                      "
                      small
                    >
                      {{
                        item.size.unit === "amount"
                          ? $t("amount") + `: ${item.size.amount}`
                          : $t("pack") + `: ${item.size.amount}`
                      }}</v-chip
                    >
                  </td>
                  <td>{{ item.price_sale | NumberFormat }}</td>
                  <td class="text-right">
                    {{ item.created_at | moment }}
                  </td>
                </tr>
              </draggable>
            </template>
            <template v-slot:footer>
              <v-data-footer
                :options.sync="options"
                :pagination.sync="page"
                :items-per-page-options="rowsPerPageItems"
                items-per-page-text="$vuetify.dataTable.itemsPerPageText"
              >
                <template v-slot:page-text="pagetext">
                  {{ footerPage(pagetext) }}
                </template>
              </v-data-footer>
            </template>
          </v-data-table>
        </main>
      </v-card-text>
    </v-card>
  </section>
</template>
<script>
import Draggable from "vuedraggable";
import { mapGetters, mapActions } from "vuex";
import { sortProductHead } from "@/utils/head";
import { toast } from "@/controllers/alert";
import { myMixin } from "@/mixin/main";

export default {
  layout: "product",
  components: { Draggable },
  mixins: [myMixin],

  data: () => ({
    tableItems: [],
    loading: false,
    rowsPerPageItems: [30, 50, 100],
    options: {
      page: 1,
      itemsPerPage: 50,
    },
  }),

  created() {
    this.fetchProduct();
  },
  computed: {
    ...mapGetters({
      sortProducts: "index/sortProducts",
    }),
    headers() {
      return sortProductHead(this);
    },

    page() {
      const body = {
        page: this.sortProducts.current_page,
        itemsPerPage: this.sortProducts.per_page,
        pageStart: this.sortProducts.from,
        pageStop: this.sortProducts.to,
        pageCount: this.sortProducts.per_page,
        itemsLength: this.sortProducts.total,
      };
      return body;
    },
  },
  watch: {
    options(next, pre) {
      const path = this.sortProducts.path;
      // split path to get only the path
      const splitPath = path.split("/");
      // get the last index of the path
      const lastIndex = splitPath.length - 1;
      // get the path
      const url = `${splitPath[lastIndex]}`;
      // set the per page
      const data = {
        url: `${url}?page=${next.page}&per_page=${next.itemsPerPage}`,
        commit: "SET_SORT_PRODUCTS",
        that: this,
      };
      // called the pagination action
      this.fetch(data);
      this.value = true;
    },
  },
  methods: {
    ...mapActions({
      fetch: "index/fetch",
    }),
    async fetchProduct() {
      const body = {
        that: this,
        url: `products_paginate?page=${this.options.page}&per_page=${this.options.itemsPerPage}`,
        commit: "SET_SORT_PRODUCTS",
      };
      await this.fetch(body);
    },
    footerPage(val) {
      const start = val.pageStart;
      const stop = val.pageStop;
      const total = val.itemsLength;
      return `${start > 2 ? start - 2 : start - 1} - ${stop} of ${total}`;
    },
    async handleChange({ moved }) {
      const { newIndex, element } = moved;
      const form = {
        oldIndex: element.number,
        newIndex: newIndex + 1,
        id: element.id,
      };
      try {
        const { data } = await this.$http.post("products_sort", form);
        if (data.status) {
          await toast(this, data.message);
          await this.fetchProduct();
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
