<template>
  <v-app>
    <v-app-bar app elevate-on-scroll flat height="65">
      <div class="container-xl d-flex align-center px-0">
        <v-list-item>
          <v-list-item-avatar>
            <v-avatar size="35">
              <v-img :src="urlLogo" />
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              {{ store?.name }}
            </v-list-item-title>
            <v-list-item-subtitle>
              ໂຕະ : {{ store?.table }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-spacer></v-spacer>
        <v-badge
          v-if="defineStore.cart.length"
          offset-x="20"
          offset-y="20"
          color="primary"
          :content="defineStore.cart.length"
        >
          <v-btn icon @click="navigator('cart')">
            <v-icon> fal fa-shopping-cart </v-icon>
          </v-btn>
        </v-badge>
        <v-btn icon v-else @click="navigator('cart')">
          <v-icon> fal fa-shopping-cart </v-icon>
        </v-btn>

        <v-btn icon @click="is_search = !is_search">
          <v-icon> fal fa-{{ is_search ? "times" : "search" }} </v-icon>
        </v-btn>
      </div>
    </v-app-bar>

    <v-main>
      <!-- Provides the application the proper gutter -->
      <div class="container-xl">
        <v-row v-if="is_search" class="mt-2">
          <v-col cols="12">
            <v-text-field
              v-model="search"
              :label="$('search')"
              outlined
              dense
              rounded
              hide-details
            >
              <template v-slot:append>
                <v-btn icon @click="onClean">
                  <v-icon> fal fa-times </v-icon>
                </v-btn>
              </template>

              <template v-slot:append-outer>
                <v-btn icon @click="onSearch()">
                  <v-icon> fal fa-search </v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
        </v-row>

        <v-subheader class="pl-0">ປະເພດສິນຄ້າ</v-subheader>
        <v-sheet class="mb-4">
          <v-slide-group v-model="value" color="primary">
            <v-slide-item
              v-for="(item, index) in categories"
              :key="index"
              v-slot="{ active, toggle }"
              :value="item.id"
            >
              <v-btn
                class="mx-2"
                :input-value="active"
                active-class="primary white--text"
                depressed
                rounded
                @click="toggle"
              >
                {{ item.la_name }}
              </v-btn>
            </v-slide-item>
          </v-slide-group>
        </v-sheet>
        <div v-for="(item, i) in products" :key="i">
          <v-subheader class="pl-0">{{ item.la_name }}</v-subheader>

          <masonry
            class="pt-1 scan-product"
            :cols="{ default: 4, 1000: 4, 700: 3, 400: 2 }"
            :gutter="{ default: '10px', 700: '10px' }"
          >
            <div
              v-for="(data, index) in item.products"
              :key="data.id + '/' + index + data.name + i + 1 + data.price_sale"
              class="body mb-2"
            >
              <div class="main-card">
                <v-card
                  rounded="lg"
                  @click="addToCart(data)"
                  :class="data.stock ? '' : ' out-of-stock'"
                >
                  <app-img
                    :img="data.img"
                    :contain="true"
                    height="120"
                    width="100%"
                  />
                  <v-card-text class="pa-2">
                    <div>
                      {{ data.name }}
                    </div>

                    <div class="d-flex">
                      <div class="primary--text">
                        {{ data.price_sale | NumberFormat }}
                      </div>
                      <div v-if="total" class="ml-auto font-weight-bold">
                        {{ total(data.id) }}
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
                <v-btn
                  v-if="haveInCart(data)"
                  @click="removeCart(data)"
                  depressed
                  color="red"
                  class="btn-trash"
                  dark
                  x-small
                  width="30"
                  height="30"
                >
                  <v-icon small>fal fa-trash</v-icon>
                </v-btn>
              </div>
            </div>
          </masonry>
        </div>
      </div>
    </v-main>
    <v-btn
      fab
      fixed
      bottom
      right
      color="primary"
      class="mb-6"
      @click="navigator('receipt')"
    >
      <v-icon> fal fa-receipt </v-icon>
    </v-btn>
    <app-loading :overlay="overlay" />
  </v-app>
</template>
<script>
import { myMixin } from "@/mixin/main";
import { mapGetters, mapMutations } from "vuex";
import { mainStore } from "@/stores/modules/cart";
import Cookies from "js-cookie";

export default {
  mixins: [myMixin],
  data: () => ({
    defineStore: mainStore(),
    is_search: false,
    value: 0,
    search: "",
    branch_id: "",
    table_id: "",
    store_code: null,
    store: null,
    category_id: null,
    categories: [],
    products: [],
  }),
  mounted() {
    this.SET_OVERLAY(true);
    const { store_id, branch_id, table_id } = this.$route.params;
    this.initLocation(store_id, branch_id, table_id);
  },
  watch: {
    value(val) {
      this.fetch_product();
    },
  },
  computed: {
    ...mapGetters({
      overlay: "index/overlay",
    }),
    urlLogo() {
      if (this.store && this.store.logo) {
        const baseURL = process.env.VUE_APP_BASE_URL;
        return `${baseURL}/uploads/${this.store.logo}`;
      } else {
        return "/images/pos.png";
      }
    },
  },

  methods: {
    ...mapMutations({
      SET_OVERLAY: "index/SET_OVERLAY",
    }),
    async initLocation(store_id, branch_id, table_id) {
      const data = await this.checkLocation(store_id, branch_id, table_id);
      if (data.status) {
        this.$http.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${data.data.token}`;
        Cookies.set("token", data.data.token);
        Cookies.set("isLoggedIn", true);
        this.store = data.data;
        this.store_code = store_id;
        this.branch_id = branch_id;
        this.table_id = table_id;
        await this.fetch_category();
        await this.fetch_product();
        return;
      }
      this.$router.push("/");
    },
    async onClean() {
      this.search = "";
      await this.fetch_product();
    },
    async onSearch() {
      if (this.search === "") return;
      await this.fetch_product();
    },

    async fetch_category() {
      try {
        const { data } = await this.$http.get(`/fetch_category`);
        const append = {
          id: 0,
          la_name: "ທັງໝົດ",
        };
        this.categories = [append, ...data.data];
      } catch (error) {
        console.log(error);
      }
    },
    async fetch_product() {
      try {
        this.SET_OVERLAY(true);
        const { data } = await this.$http.get(
          `/fetch_product?search=${this.search}&category_id=${this.value}`
        );
        this.products = data.data;
        this.SET_OVERLAY(false);
      } catch (error) {
        console.log(error);
      }
    },
    async addToCart(item) {
      const body = {
        that: this,
        payload: item,
      };
      this.defineStore.addToCart(body);
    },
    haveInCart(item) {
      const value = this.defineStore.cart.find((e) => e.id === item.id);
      if (value != null && value != undefined) {
        return true;
      } else {
        return false;
      }
    },
    total(id) {
      const value = this.defineStore.cart.find((e) => e.id === id);
      if (value != null && value != undefined) {
        return value.qty;
      }
    },
    async removeCart(item) {
      const body = {
        that: this,
        payload: item,
      };
      this.defineStore.removeCart(body);
    },
    navigator(path) {
      const params = `store_id=${this.store_code}&branch_id=${this.branch_id}&table_id=${this.table_id}`;
      this.$router.push(`/${path}?${params}`);
    },
  },
};
</script>
