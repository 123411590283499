<template>
  <section>
    <data-table
      :headers="headers"
      :items="items"
      commit="SET_INVOICES"
      :viewItem="viewItem"
      :editItem="editItem"
      :deleteItem="deleteItem"
    >
      <div slot="activator" class="row justify-sm-space-between">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
          <v-card-title class="py-0 pl-0">
            {{ $t("invoice") }}
          </v-card-title>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
          <TextSearch
            v-model="search"
            @search="fetchItem()"
            @onClear="
              search = '';
              fetchItem();
            "
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 text-right">
          <v-btn depressed color="primary" @click="newItem">
            <v-icon left>fal fa-plus-circle</v-icon>
            {{ $t("add_invoice") }}
          </v-btn>
        </div>
      </div>
    </data-table>
    <!-- Invoice Dialog -->
    <app-custom-dialog
      v-model="dialog"
      :footer="false"
      title="invoice_details"
      width="800px"
      @submit="submitForm"
    >
      <div slot="activator">
        <!-- Invoice Print Template -->
        <div class="invoice-container" id="saveInvoice">
          <!-- Invoice Header -->
          <div class="invoice-header">
            <div class="company-info">
              <div class="company-logo">
                <v-img
                  contain
                  width="70"
                  height="70"
                  src="/images/logo.png"
                ></v-img>
              </div>
              <div class="company-details">
                <h1 class="company-name">PosSmart</h1>
                <p class="company-tagline">ລະບົບການຂາຍທີ່ທັນສະໄຫມ</p>
              </div>
            </div>
            <div class="invoice-meta">
              <div class="invoice-number">
                <h2 class="invoice-label">{{ $t("invoice") }}</h2>
                <p class="invoice-value">#{{ form.invoice_id }}</p>
              </div>
              <v-chip
                class="status-chip"
                :color="getStatusColor(form.payment_status)"
              >
                {{ $t(form.payment_status) }}
              </v-chip>
            </div>
          </div>

          <!-- Invoice Body -->
          <div class="invoice-body">
            <!-- Billing Information -->
            <div class="billing-section">
              <div class="billing-info">
                <h3 class="section-title">{{ $t("invoice_to") }}</h3>
                <div class="client-details">
                  <h4 class="client-name">{{ form.store.name }}</h4>
                  <p class="client-contact">{{ form.store.phone_no }}</p>
                  <p class="client-address">{{ form.store.address }}</p>
                </div>
              </div>
              <div class="invoice-details">
                <div class="detail-item">
                  <span class="detail-label">{{ $t("invoice_no") }}</span>
                  <span class="detail-value">{{ form.invoice_id }}</span>
                </div>
                <div class="detail-item">
                  <span class="detail-label">{{ $t("date") }}</span>
                  <span class="detail-value">{{ form.date | DatePrint }}</span>
                </div>
                <div class="detail-item">
                  <span class="detail-label">{{ $t("payment_method") }}</span>
                  <span class="detail-value">{{
                    $t(form.payment_method)
                  }}</span>
                </div>
              </div>
            </div>

            <!-- Invoice Table -->
            <div class="invoice-table-container">
              <table class="invoice-table">
                <thead>
                  <tr>
                    <th width="5%">{{ $t("no") }}</th>
                    <th width="45%">{{ $t("item") }}</th>
                    <th width="15%" class="text-center">
                      {{ $t("quantity") }}
                    </th>
                    <th width="15%" class="text-right">{{ $t("price") }}</th>
                    <th width="20%" class="text-right">{{ $t("total") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in form.invoice_detail" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>{{ item.title }}</td>
                    <td class="text-center">{{ item.quantity | ToNumber }}</td>
                    <td class="text-right">{{ item.price | ToNumber }}</td>
                    <td class="text-right">{{ item.total | ToNumber }}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr class="subtotal-row">
                    <td colspan="4" class="text-right">{{ $t("subtotal") }}</td>
                    <td class="text-right">
                      {{ getSubtotal() | NumberFormat }}
                    </td>
                  </tr>
                  <tr v-if="form.tax_amount > 0" class="tax-row">
                    <td colspan="4" class="text-right">
                      {{ $t("tax") }} ({{ form.tax_rate }}%)
                    </td>
                    <td class="text-right">
                      {{ form.tax_amount | NumberFormat }}
                    </td>
                  </tr>
                  <tr class="total-row">
                    <td colspan="4" class="text-right total-label">
                      {{ $t("total") }}
                    </td>
                    <td class="text-right total-value">
                      {{ form.total | NumberFormat }}
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>

            <!-- Notes Section -->
            <div class="notes-section" v-if="form.notes">
              <h3 class="section-title">{{ $t("notes") }}</h3>
              <p class="notes-content">{{ form.notes }}</p>
            </div>
          </div>

          <!-- Invoice Footer -->
          <div class="invoice-footer">
            <div class="footer-columns">
              <div class="footer-col company-col">
                <h3 class="footer-title">Devla</h3>
                <p class="footer-text">ນາສ້າງໃຜ່, ໄຊເສດຖາ ນະຄອນຫຼວຫຼງວຽງຈັນ</p>
                <p class="footer-text">https://devla.la</p>
                <p class="footer-text">02028492947</p>
              </div>
              <div class="footer-col payment-col">
                <div class="payment-info">
                  <v-img
                    width="100"
                    height="100"
                    src="/images/qr.png"
                    class="qr-code"
                  />
                  <div class="account-details">
                    <p class="account-name">
                      {{ account.name }} - {{ account.currency }}
                    </p>
                    <p class="account-number">{{ account.number }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="thank-you-message">
              <p>{{ $t("thank_you_message") }}</p>
            </div>
          </div>
        </div>

        <!-- Action Buttons -->
        <div class="invoice-actions">
          <v-btn
            depressed
            color="primary"
            @click="saveInvoice"
            class="action-btn"
          >
            <v-icon small left>fal fa-download</v-icon>
            {{ $t("save_pdf") }}
          </v-btn>
          <v-btn depressed color="secondary" class="action-btn ml-2">
            <v-icon small left>fal fa-print</v-icon>
            {{ $t("print") }}
          </v-btn>
          <v-btn text color="primary" class="action-btn ml-2">
            <v-icon small left>fal fa-envelope</v-icon>
            {{ $t("email") }}
          </v-btn>
        </div>
      </div>
    </app-custom-dialog>
    <app-custom-dialog
      v-model="dialogAdd"
      :title="titleForm"
      @submit="submitForm"
    >
      <div slot="activator">
        <TextSelect
          label="store"
          v-model="form.store_id"
          itemValue="id"
          text="name"
          :items="stores"
        />
        <DatePicker label="invoice_date" v-model="form.date" />
        <TextSelect label="status" v-model="form.status" :items="status" />
        <TextSelect
          label="payment_method"
          v-model="form.payment_method"
          :items="payment_method"
        />
        <TextSelect
          label="payment_status"
          v-model="form.payment_status"
          :items="payment_status"
        />
        <v-row class="mt-4">
          <v-col cols="6">
            <v-btn depressed small color="primary" @click="addMoreObject">
              <v-icon left>fal fa-plus-circle</v-icon>
              Add More
            </v-btn>
          </v-col>
          <v-col cols="6" class="text-right">
            <v-btn depressed small color="error" @click="removeItem(index)">
              <v-icon left>fal fa-minus-circle</v-icon>
              Remove
            </v-btn>
          </v-col>
        </v-row>
        <v-chip-group>
          <v-chip
            v-for="(item, index) in paymentList"
            :key="index"
            @click="setDefaultValue(item)"
          >
            {{ item.title }}
          </v-chip>
        </v-chip-group>

        <v-divider class="mb-2"></v-divider>
        <template v-for="(item, index) in form.invoice_detail">
          <section :key="index">
            <TTextField label="title" v-model="item.title" />
            <VuetifyMoney
              label="quantity"
              v-model="item.quantity"
              :options="options"
            />
            <VuetifyMoney
              label="price"
              v-model="item.price"
              :options="options"
            />
          </section>
        </template>
      </div>
    </app-custom-dialog>
  </section>
</template>

<script>
import DataTable from "@/components/DataTable";
import { mapGetters, mapActions } from "vuex";
import { invoiceHead } from "@/utils/head.js";
import TTextField from "@/components/global/TextField";
import AppDetail from "@/components/products/details";
import { myMixin } from "@/mixin/main";
import { success, confirm } from "@/controllers/alert";
import { PACKAGE } from "@/validations/admin";
import VuetifyMoney from "@/components/VuetifyMoney";
import TextSelect from "@/components/global/TextSelect";
import html2pdf, { f } from "html2pdf.js";
const today = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
  .toISOString()
  .substr(0, 10);
export default {
  mixins: [PACKAGE, myMixin],
  data: () => ({
    update: false,
    dialog: false,
    dialogAdd: false,
    titleForm: "new_invoice",
    stores: [],
    items: {},
    search: "",
    status: ["pending", "shipped", "delivered", "cancelled"],
    payment_method: ["cash", "transfer", "other"],
    payment_status: ["pending", "paid", "failed"],
    paymentList: [
      {
        title: "Monthly plan",
        quantity: 1,
        price: 300000,
      },
      {
        title: "Custom product",
        quantity: 1,
        price: 500,
      },
    ],
    formUpdate: {},
    options: {
      precision: 0,
    },
    form: {
      store: {},
      store_id: "",
      date: today,
      invoice_detail: [
        {
          title: "",
          quantity: 0,
          price: 0,
        },
      ],
    },
  }),
  async created() {
    await this.fetchItem();
  },
  mounted() {
    this.getAllStore();
  },
  components: {
    DataTable,
    TTextField,
    VuetifyMoney,
    AppDetail,
    TextSelect,
  },
  computed: {
    ...mapGetters({
      account: "index/account",
    }),
    headers() {
      return invoiceHead(this);
    },

    titleStatus() {
      return this.update ? "Update" : "New";
    },
  },

  methods: {
    ...mapActions({
      fetch: "index/fetch",
    }),
    async fetchItem() {
      const { data } = await this.$http.get(`invoices?search=${this.search}`);
      this.items = data.data;
    },

    async newItem() {
      await Help.clearForm(this.form, this);
      await this.fetchItem();
      this.titleForm = "new_invoice";
      this.form = {
        store: {},
        store_id: "",
        status: "pending",
        payment_method: "transfer",
        payment_status: "pending",
        date: today,
        invoice_detail: [
          {
            title: "",
            quantity: 0,
            price: 0,
          },
        ],
      };
      this.update = false;
      this.dialogAdd = !this.dialogAdd;
    },
    async getAllStore() {
      const { data } = await this.$http.get(`get_all_store`);
      this.stores = data.data;
    },
    async editItem({ id }) {
      const { data } = await this.$http.get(`invoices/${id}`);
      this.form = data.data;
      this.form.store_id = data.data.store;
      this.update = true;
      this.titleForm = "update_invoice";
      this.dialogAdd = !this.dialogAdd;
    },
    async deleteItem({ id }) {
      try {
        const { isConfirmed } = await confirm({
          props: this,
          text: "want_to_delete",
        });
        if (isConfirmed) {
          const { data } = await this.$http.delete(`invoices/${id}`);
          if (data.status) {
            await Help.clearForm(this.form, this);
            await this.fetchItem();
            await success(this, data.message);
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    async viewItem({ id }) {
      try {
        const { data } = await this.$http.get(`invoices/${id}`);
        this.form = data.data;
        this.dialog = !this.dialog;
      } catch (error) {
        console.error(error);
      }
    },
    async addMoreObject() {
      this.form.invoice_detail.push({
        title: "",
        quantity: 0,
        price: 0,
      });
    },

    async removeItem(index) {
      this.form.invoice_detail.splice(index, 1);
    },

    async submitForm() {
      var result;
      try {
        const body = {
          store_id: this.form.store_id.id,
          date: this.form.date,
          status: this.form.status,
          payment_method: this.form.payment_method,
          payment_status: this.form.payment_status,
          data: this.form.invoice_detail,
        };
        if (this.update) {
          result = await this.$http.put(`invoices/${this.form.id}`, body);
        } else {
          result = await this.$http.post("invoices", body);
        }
        if (result.data.status) {
          await Help.clearForm(this.form, this);
          await this.fetchItem();
          this.dialogAdd = !this.dialogAdd;
          await success(this, result.data.message);
        }
      } catch (error) {
        console.error(error);
      }
    },

    async saveInvoice() {
      try {
        const element = document.getElementById("saveInvoice");
        const opt = {
          margin: 0.2,
          filename: `invoice_${this.form.invoice_id}.pdf`,
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 2, useCORS: true },
          jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
          pagebreak: { mode: ["avoid-all", "css", "legacy"] },
          html2canvas: {
            scale: 2,
            useCORS: true,
            onclone: (document) => {
              const style = document.createElement("style");
              style.innerHTML = `
                body {
                  font-family: "Noto Sans Lao Looped", sans-serif !important;
                }
              `;
              document.head.appendChild(style);
            },
          },
        };
        html2pdf().from(element).set(opt).save();
      } catch (error) {
        console.log("error", error);
      }
    },
    getStatusColor(status) {
      const statusColors = {
        pending: "warning",
        paid: "success",
        failed: "error",
        cancelled: "grey",
      };
      return statusColors[status] || "grey";
    },
    getSubtotal() {
      if (!this.form.invoice_detail || this.form.invoice_detail.length === 0)
        return 0;

      return this.form.invoice_detail.reduce((total, item) => {
        const price = parseFloat(item.price) || 0;
        const quantity = parseFloat(item.quantity) || 0;
        return total + price * quantity;
      }, 0);
    },
    setDefaultValue(val) {
      // set the last value of invoice_detail to the selected value
      this.form = {
        ...this.form,
        invoice_detail: this.form.invoice_detail.map((item, index) => {
          if (index === this.form.invoice_detail.length - 1) {
            return {
              ...item,
              title: val.title,
              quantity: val.quantity,
              price: val.price,
            };
          }
          return item;
        }),
      };
    },
  },
};
</script>
<style lang="scss" scoped>
// Invoice Container
.invoice-container {
  background-color: white;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.08);
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  color: #333;
  position: relative;
  font-family: "Noto Sans Lao Looped", sans-serif;
}

// Invoice Header
.invoice-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eaeaea;
}

.company-info {
  display: flex;
  align-items: center;
  gap: 15px;
}

.company-name {
  font-size: 24px;
  font-weight: 700;
  color: #3c4858;
  margin: 0;
  line-height: 1.2;
}

.company-tagline {
  font-size: 14px;
  color: #606f7b;
  margin: 4px 0 0;
}

.invoice-meta {
  text-align: right;
}

.invoice-label {
  color: #606f7b;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin: 0 0 5px;
}

.invoice-value {
  font-size: 20px;
  font-weight: 700;
  color: #3c4858;
  margin: 0;
}

.status-chip {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  margin-top: 10px;
}

// Invoice Body
.invoice-body {
  margin-bottom: 30px;
}

// Billing Section
.billing-section {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.billing-info,
.invoice-details {
  flex: 1;
}

.invoice-details {
  text-align: right;
}

.section-title {
  color: #6b46c1;
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.client-name {
  font-size: 18px;
  font-weight: 600;
  color: #3c4858;
  margin: 0 0 5px;
}

.client-contact,
.client-address {
  color: #606f7b;
  margin: 0 0 5px;
  font-size: 14px;
  line-height: 1.4;
}

.detail-item {
  margin-bottom: 8px;
  line-height: 1.4;
}

.detail-label {
  color: #606f7b;
  margin-right: 8px;
  font-weight: 600;
}

.detail-value {
  color: #3c4858;
  font-weight: 500;
}

// Invoice Table
.invoice-table-container {
  margin: 30px 0;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
}

.invoice-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
}

.invoice-table th {
  background-color: #6b46c1;
  color: white;
  padding: 12px 15px;
  text-align: left;
  font-weight: 600;
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.invoice-table td {
  padding: 12px 15px;
  border-bottom: 1px solid #eaeaea;
  color: #3c4858;
}

.invoice-table tbody tr:last-child td {
  border-bottom: 2px solid #eaeaea;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.subtotal-row td,
.tax-row td {
  background-color: #f8fafc;
  padding: 10px 15px;
  border-bottom: 1px solid #eaeaea;
}

.total-row td {
  background-color: #f1eaff;
  padding: 15px;
  font-weight: 700;
  color: #3c4858;
  border-bottom: none;
}

.total-label {
  font-size: 16px;
  color: #6b46c1;
}

.total-value {
  font-size: 18px;
  color: #6b46c1;
}

// Notes Section
.notes-section {
  background-color: #f8fafc;
  padding: 15px;
  border-radius: 8px;
  margin-top: 30px;
}

.notes-content {
  color: #606f7b;
  font-size: 14px;
  line-height: 1.5;
  margin: 0;
}

// Invoice Footer
.invoice-footer {
  margin-top: 40px;
  padding-top: 20px;
  border-top: 1px solid #eaeaea;
}

.footer-columns {
  display: flex;
  justify-content: space-between;
}

.footer-col {
  flex: 1;
}

.footer-title {
  font-size: 18px;
  font-weight: 600;
  color: #3c4858;
  margin: 0 0 10px;
}

.footer-text {
  color: #606f7b;
  margin: 0 0 5px;
  font-size: 14px;
  line-height: 1.4;
}

.payment-info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.account-details {
  text-align: right;
  margin-top: 10px;
}

.account-name,
.account-number {
  color: #606f7b;
  margin: 3px 0;
  font-size: 14px;
}

.thank-you-message {
  text-align: center;
  margin-top: 30px;
  font-size: 14px;
  color: #6b46c1;
  font-weight: 500;
}

// Action Buttons
.invoice-actions {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.action-btn {
  text-transform: none;
  font-weight: 500;
  border-radius: 6px;
}

// Media Queries for Responsive Design
@media (max-width: 600px) {
  .invoice-container {
    padding: 20px;
  }

  .invoice-header {
    flex-direction: column;
  }

  .company-info {
    margin-bottom: 20px;
  }

  .invoice-meta {
    text-align: left;
  }

  .billing-section {
    flex-direction: column;
  }

  .invoice-details {
    text-align: left;
    margin-top: 20px;
  }

  .footer-columns {
    flex-direction: column;
  }

  .payment-info {
    align-items: flex-start;
    margin-top: 20px;
  }

  .payment-col {
    margin-top: 20px;
  }

  .account-details {
    text-align: left;
  }
}
</style>
