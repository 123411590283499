<template>
  <section>
    <v-row>
      <!-- Product List Panel -->
      <v-col cols="12" md="3">
        <v-card class="product-card h-100" rounded="lg">
          <v-toolbar flat class="px-4">
            <v-toolbar-title>{{ $t("product_item") }}</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="fetchItem()" title="Refresh">
              <v-icon x-small>fal fa-redo-alt</v-icon>
            </v-btn>
          </v-toolbar>

          <v-card-text class="pt-4">
            <div class="mb-4">
              <TextSearch
                v-model="search"
                @search="fetchItem()"
                @onClear="
                  search = '';
                  fetchItem();
                "
              />
            </div>

            <div class="product-list-container overflow-auto">
              <v-list>
                <v-list-item
                  v-for="(item, index) in product_purchase.data"
                  :key="index"
                  class="product-list-item"
                >
                  <v-list-item-avatar
                    rounded="lg"
                    size="50"
                    class="mr-3"
                    color="grey lighten-2"
                  >
                    <app-img :img="item.img" />
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title class="product-name">{{
                      item.name
                    }}</v-list-item-title>
                    <v-list-item-subtitle class="price-text">
                      {{ (item.price_buy / item.exchange_rate) | NumberFormat }}
                      {{ item.currency.code }}
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn
                      color="primary"
                      fab
                      x-small
                      @click="setToPurchase(item.code)"
                    >
                      <v-icon x-small>fal fa-plus</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>

                <v-list-item
                  v-if="
                    !product_purchase.data || product_purchase.data.length === 0
                  "
                >
                  <v-list-item-content class="text-center py-8">
                    <v-icon size="64" color="grey lighten-1"
                      >fal fa-box-open</v-icon
                    >
                    <div class="mt-4 text-body-1 grey--text">
                      {{ $t("no_products_found") }}
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Purchase Cart Panel -->
      <v-col cols="12" md="9">
        <v-card class="cart-card h-100" rounded="lg">
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="desserts"
              hide-default-footer
              class="purchase-table elevation-0 overflow-auto"
              :loading="overlay"
              loading-text="Loading products..."
              fixed-header
              height="50vh"
            >
              <template v-slot:item.index="{ index }">
                {{ index + 1 }}
              </template>

              <template v-slot:item.img="{ item }">
                <v-avatar
                  tile
                  size="40"
                  class="rounded-lg"
                  color="grey lighten-2"
                >
                  <app-img :img="item.img" />
                </v-avatar>
              </template>

              <template v-slot:item.price_display="{ item }">
                {{ (item.price_buy / item.exchange_rate) | NumberFormat }}
              </template>

              <template v-slot:item.qty="{ item }">
                <div class="quantity-control">
                  <v-btn
                    icon
                    x-small
                    @click="decrement(item)"
                    color="grey"
                    class="mr-1"
                  >
                    <v-icon x-small>fal fa-minus</v-icon>
                  </v-btn>
                  <span class="quantity-value">{{
                    item.qty | NumberFormat
                  }}</span>
                  <v-btn
                    icon
                    x-small
                    @click="increment(item)"
                    color="primary"
                    class="ml-1"
                  >
                    <v-icon x-small>fal fa-plus</v-icon>
                  </v-btn>
                </div>
              </template>

              <template v-slot:item.total="{ item }">
                <span class="font-weight-medium">
                  {{
                    ((item.price_buy / item.exchange_rate) * item.qty)
                      | NumberFormat
                  }}
                </span>
              </template>

              <template v-slot:item.actions="{ item, index }">
                <div class="action-buttons">
                  <v-btn
                    x-small
                    class="rounded-lg mr-1"
                    fab
                    depressed
                    color="info"
                    @click="viewItem(item)"
                    title="View details"
                  >
                    <v-icon x-small>fal fa-eye</v-icon>
                  </v-btn>
                  <v-btn
                    x-small
                    class="rounded-lg mr-1"
                    fab
                    depressed
                    color="primary"
                    @click="editItem(item)"
                    title="Edit item"
                  >
                    <v-icon x-small>fal fa-edit</v-icon>
                  </v-btn>
                  <v-btn
                    x-small
                    class="rounded-lg"
                    fab
                    depressed
                    color="error"
                    @click="deleteItem(index)"
                    title="Remove item"
                  >
                    <v-icon x-small>fal fa-trash</v-icon>
                  </v-btn>
                </div>
              </template>

              <template v-slot:footer>
                <v-divider></v-divider>

                <!-- Cart Summary with Currency Breakdown -->
                <div class="mt-4">
                  <div
                    v-if="Object.keys(summary).length > 0"
                    class="summary-section"
                  >
                    <v-row>
                      <v-col cols="12">
                        <div class="text-h6 mb-4">
                          {{ $t("currency_summary") }}
                        </div>
                        <v-row>
                          <v-col
                            cols="12"
                            md="4"
                            v-for="(value, currency) in summary"
                            :key="currency"
                          >
                            <v-card
                              outlined
                              rounded="lg"
                              class="summary-card pa-4"
                            >
                              <div class="text-h6 primary--text mb-3">
                                {{ currency }}
                              </div>
                              <v-row dense>
                                <v-col
                                  cols="7"
                                  class="text-body-2 font-weight-medium"
                                  >{{ $t("exchange_rate") }}:</v-col
                                >
                                <v-col
                                  cols="5"
                                  class="text-right text-body-2"
                                  >{{
                                    getExchangeRate(currency) | NumberFormat
                                  }}</v-col
                                >

                                <v-col
                                  cols="7"
                                  class="text-body-2 font-weight-medium"
                                  >{{ $t("quantity") }}:</v-col
                                >
                                <v-col
                                  cols="5"
                                  class="text-right text-body-2"
                                  >{{ value.qty | NumberFormat }}</v-col
                                >

                                <v-col
                                  cols="7"
                                  class="text-body-2 font-weight-medium"
                                  >{{ $t("price_buy") }}:</v-col
                                >
                                <v-col
                                  cols="5"
                                  class="text-right text-body-2 red--text"
                                  >{{ value.price_buy | NumberFormat }}</v-col
                                >

                                <v-col
                                  cols="7"
                                  class="text-body-2 font-weight-medium"
                                  >{{ $t("price_sale") }}:</v-col
                                >
                                <v-col
                                  cols="5"
                                  class="text-right text-body-2"
                                  >{{ value.price_sale | NumberFormat }}</v-col
                                >

                                <v-col
                                  cols="7"
                                  class="text-body-2 font-weight-medium"
                                  >{{ $t("profit") }}:</v-col
                                >
                                <v-col
                                  cols="5"
                                  class="text-right text-body-2"
                                  :class="
                                    value.price_sale - value.price_buy > 0
                                      ? 'green--text'
                                      : 'red--text'
                                  "
                                >
                                  {{
                                    (value.price_sale - value.price_buy)
                                      | NumberFormat
                                  }}
                                </v-col>
                              </v-row>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </div>

                  <!-- Total Summary -->
                  <v-card outlined rounded="lg" class="total-summary-card mt-4">
                    <v-row no-gutters>
                      <v-col cols="12" md="8">
                        <div class="pa-4">
                          <div class="text-h6 mb-3">
                            {{ $t("total_summary") }}
                          </div>
                          <v-simple-table dense>
                            <template v-slot:default>
                              <tbody>
                                <tr>
                                  <td class="text-body-1 font-weight-medium">
                                    {{ $t("price_buy") }}
                                  </td>
                                  <td
                                    class="text-right text-body-1 red--text font-weight-bold"
                                  >
                                    {{ total_summary.price_buy | NumberFormat }}
                                  </td>
                                </tr>
                                <tr>
                                  <td class="text-body-1 font-weight-medium">
                                    {{ $t("price_sale") }}
                                  </td>
                                  <td
                                    class="text-right text-body-1 primary--text font-weight-bold"
                                  >
                                    {{
                                      total_summary.price_sale | NumberFormat
                                    }}
                                  </td>
                                </tr>
                                <tr>
                                  <td class="text-body-1 font-weight-medium">
                                    {{ $t("profit") }}
                                  </td>
                                  <td
                                    class="text-right text-body-1 font-weight-bold"
                                    :class="
                                      total_summary.profit > 0
                                        ? 'green--text'
                                        : 'red--text'
                                    "
                                  >
                                    {{ total_summary.profit | NumberFormat }}
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </div>
                      </v-col>
                      <v-col cols="12" md="4">
                        <div
                          class="pa-4 d-flex align-center justify-center h-100"
                        >
                          <v-btn
                            color="primary"
                            x-large
                            block
                            @click="addStock()"
                            :disabled="!desserts.length"
                            class="add-stock-btn"
                          >
                            <v-icon left>fal fa-box-up</v-icon>
                            {{ $t("add_stock") }}
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card>
                </div>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- View Item Dialog -->
    <app-custom-dialog v-model="dialog" :footer="false" title="description">
      <div slot="activator">
        <AppDetail :item="form" />
      </div>
    </app-custom-dialog>

    <!-- Add/Edit Item Dialog -->
    <app-custom-dialog
      v-model="dialogAdd"
      title="add_stock"
      @submit="stockPrepare(form)"
    >
      <div slot="activator">
        <VuetifyMoney label="quantity" v-model.number="form.qty" />
        <VuetifyMoney
          label="price_buy"
          v-model.number="form.price_buy"
          :suffix="currency"
          class="mt-3"
        />
        <VuetifyMoney
          v-model="profit_percent"
          label="profit_percent"
          suffix="%"
          class="mt-3"
        />
        <VuetifyMoney
          label="price_sale"
          v-model.number="form.price_sale"
          :suffix="currency"
          class="mt-3"
        />
        <DatePicker label="expired_date" v-model="form.expired_date" />
        <TextSelect
          v-if="suppliers.length"
          label="supplier"
          v-model="form.vendor_id"
          itemValue="id"
          text="name"
          :items="suppliers"
          class="mt-3"
        />
      </div>
    </app-custom-dialog>

    <!-- Loading Overlay -->
    <app-loading :overlay="overlay" />
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AppDetail from "@/components/products/details";
import { confirm, toast } from "@/controllers/alert";
import _ from "lodash";
import { myMixin } from "@/mixin/main";
import VuetifyMoney from "@/components/VuetifyMoney";
import TextSelect from "@/components/global/TextSelect";
const today = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
  .toISOString()
  .substr(0, 10);

export default {
  mixins: [myMixin],
  components: {
    AppDetail,
    VuetifyMoney,
    TextSelect,
  },
  data() {
    return {
      profit_percent: 0,
      dialogAdd: false,
      dialog: false,
      overlay: false,
      search: "",
      desserts: [],
      update: false,
      form: {
        expired_date: today,
      },
      headers: [
        { text: this.$t("no"), value: "index", align: "left", sortable: false },
        {
          text: this.$t("code"),
          value: "code",
          align: "left",
          sortable: false,
        },
        {
          text: this.$t("image"),
          value: "img",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("name"),
          value: "name",
          align: "left",
          sortable: false,
        },
        { text: this.$t("currency"), value: "currency.code", align: "center" },
        { text: this.$t("price"), value: "price_display", align: "right" },
        {
          text: this.$t("quantity"),
          value: "qty",
          align: "center",
          width: "150px",
        },
        { text: this.$t("total_price"), value: "total", align: "right" },
        {
          text: this.$t("manage"),
          value: "actions",
          align: "center",
          sortable: false,
          width: "150px",
        },
      ],
    };
  },

  created() {
    this.fetchItem();
    this.fetchVendor();
    this.fetchBranch();
    // Initialize search debouncer
    this.searchDebounce = _.debounce(this.fetchItem, 500);
  },

  watch: {
    profit_percent(newVal) {
      const valInt = parseFloat(newVal);
      const price_buy = parseFloat(this.form.price_buy);
      const profit = (price_buy * valInt) / 100;
      this.form.price_sale = (price_buy + profit).toFixed(2);
    },
  },

  computed: {
    ...mapGetters({
      user: "auth/user",
      suppliers: "index/suppliers",
      branches: "index/branches",
      product_purchase: "index/product_purchase",
    }),

    total_buy() {
      let total = 0;
      this.desserts.forEach((item) => {
        const rate = item.exchange_rate;
        const price = item.price_buy / rate;
        total += price * item.qty;
      });
      return total;
    },

    // sum price buy with exchange rate and qty for each item
    sum_price_buy() {
      let total = 0;
      this.desserts.forEach((item) => {
        const rate = item.exchange_rate;
        const price = item.price_buy * rate;
        total += price * item.qty;
      });
      return total;
    },

    sum_price_sale() {
      let total = 0;
      this.desserts.forEach((item) => {
        const rate = item.exchange_rate;
        const price = item.price_sale * rate;
        total += price * item.qty;
      });
      return total;
    },

    sum_profit() {
      return this.sum_price_sale - this.sum_price_buy;
    },

    total_summary() {
      // loop through desserts and calculate total summary
      const summary = {
        price_buy: 0,
        price_sale: 0,
        profit: 0,
        qty: 0,
      };
      this.desserts.forEach((item) => {
        const rate = item.exchange_rate;
        const price_buy = item.price_buy / rate;
        const price_sale = item.price_sale / rate;
        summary.price_buy += price_buy * item.qty * rate;
        summary.price_sale += price_sale * item.qty * rate;
        summary.qty += parseFloat(item.qty);
        summary.profit += (price_sale - price_buy) * item.qty * rate;
      });

      return summary;
    },

    total_sale() {
      let total = 0;
      this.desserts.forEach((item) => {
        const rate = item.exchange_rate;
        const price = item.price_sale / rate;
        total += price * item.qty;
      });
      return total;
    },

    total_profit() {
      return this.total_sale - this.total_buy;
    },

    currency() {
      if (this.form.currency) {
        return this.form.currency.code;
      }
      return "";
    },

    // Summary with improved structure
    summary() {
      const summary = {};
      this.desserts.forEach((item) => {
        const rate = item.exchange_rate;
        const price_buy = item.price_buy / rate;
        const price_sale = item.price_sale / rate;

        if (!summary[item.currency.code]) {
          summary[item.currency.code] = {
            price_buy: 0,
            price_sale: 0,
            qty: 0,
          };
        }

        summary[item.currency.code].price_buy += price_buy * item.qty;
        summary[item.currency.code].price_sale += price_sale * item.qty;
        summary[item.currency.code].qty += parseFloat(item.qty);
      });

      return summary;
    },
  },

  methods: {
    ...mapActions({
      fetch: "index/fetch",
    }),

    async fetchItem() {
      this.overlay = true;
      const body = {
        that: this,
        url: `products_all?q=${this.search}`,
        commit: "SET_PRODUCT_PURCHASE",
      };
      await this.fetch(body);
      this.overlay = false;
    },

    async fetchBranch() {
      const body = {
        that: this,
        url: "branches",
        commit: "SET_BRANCHES",
      };
      await this.fetch(body);
    },

    async fetchVendor() {
      const body = {
        that: this,
        url: "suppliers",
        commit: "SET_SUPPLIERS",
      };
      await this.fetch(body);
    },

    clearSearch() {
      this.search = "";
      this.fetchItem();
    },

    decrement(item) {
      if (item.qty <= 1) {
        // Ask for confirmation before removing
        this.confirmDeleteItem(item);
      } else {
        item.qty -= 1;
      }
    },

    increment(item) {
      item.qty += 1;
    },

    async confirmDeleteItem(item) {
      const { isConfirmed } = await confirm({
        props: this,
        text: "want_to_delete",
      });
      if (isConfirmed) {
        const index = this.desserts.findIndex((i) => i.id === item.id);
        if (index !== -1) {
          this.desserts.splice(index, 1);
          toast(this, "item_deleted_successfully");
        }
      }
    },

    async viewItem({ id }) {
      try {
        this.overlay = true;
        const { data } = await this.$http.get(`products/${id}`);
        if (data.status) {
          this.form = data.data;
          this.dialog = true;
        }
        this.overlay = false;
      } catch (error) {
        this.overlay = false;
        console.error("Error fetching product details:", error);
        toast(this, "error_fetching_data", "error");
      }
    },

    editItem(item) {
      this.update = true;
      // Store the original values but display converted values in the form
      const price_buy = (item.price_buy / item.exchange_rate).toFixed(2);
      const price_sale = (item.price_sale / item.exchange_rate).toFixed(2);

      this.form = {
        ...item,
        price_buy: parseFloat(price_buy),
        price_sale: parseFloat(price_sale),
      };

      // Calculate profit percentage
      const profit = this.form.price_sale - this.form.price_buy;
      this.profit_percent =
        this.form.price_buy > 0
          ? Math.round((profit / this.form.price_buy) * 100)
          : 0;

      this.dialogAdd = true;
    },

    async deleteItem(index) {
      const { isConfirmed } = await confirm({
        props: this,
        text: "want_to_delete",
      });
      if (!isConfirmed) return;

      this.desserts.splice(index, 1);
      toast(this, "item_deleted_successfully");
    },

    async setToPurchase(search) {
      try {
        if (!search) return;
        this.overlay = true;

        const { data } = await this.$http.get(`products_barcode/${search}`);
        if (data.status) {
          const _data = data.data;
          // Check for duplicate item
          const duplicate = this.desserts.find((item) => item.id === _data.id);

          if (duplicate) {
            duplicate.qty += 1;
            toast(this, "quantity_increased");
          } else {
            // Add new item to cart
            this.desserts.push({ ..._data, qty: 1 });

            // Remove from available products list
            this.product_purchase.data = this.product_purchase.data.filter(
              (item) => item.id !== _data.id
            );

            toast(this, "item_added_to_cart");
          }
        }
        this.overlay = false;
      } catch (error) {
        this.overlay = false;
        console.error("Error adding product:", error);
        toast(this, "error_adding_product", "error");
      }
    },

    stockPrepare(form) {
      // Find the product in desserts array with matching id
      const index = this.desserts.findIndex((item) => item.id === form.id);

      if (index !== -1) {
        const value = this.desserts[index];

        // Update properties
        value.qty = parseFloat(form.qty);

        // Convert prices back with exchange rate
        value.price_buy = form.price_buy * value.exchange_rate;
        value.price_sale = form.price_sale * value.exchange_rate;
        value.expired_date = form.expired_date;
        value.vendor_id = this.getVendorId(form.vendor_id);

        // Update profit percentage
        const profit = form.price_sale - form.price_buy;
        value.profit_percent =
          form.price_buy > 0 ? Math.round((profit / form.price_buy) * 100) : 0;

        // Close dialog and show success message
        this.dialogAdd = false;
        this.update = false;
        toast(this, "item_updated_successfully");
      } else {
        console.error("Product not found in purchase list");
        toast(this, "item_not_found", "error");
      }
    },

    async addStock() {
      try {
        if (!this.desserts.length) {
          toast(this, "no_items_to_purchase", "warning");
          return;
        }

        const { isConfirmed } = await confirm({
          props: this,
          text: "want_to_purchase",
        });

        if (isConfirmed) {
          this.overlay = true;

          // Prepare data for API
          let arr = this.desserts.map((item) => ({
            product_id: item.id,
            qty: item.qty,
            price_buy: item.price_buy / item.exchange_rate,
            price_sale: item.price_sale / item.exchange_rate,
            expired_date: item.expired_date,
            vendor_id: this.getVendorId(item.vendor_id),
          }));

          const form = { data: arr };

          // Submit to API
          const { data } = await this.$http.post("stock", form);

          if (data.status) {
            this.fetchItem();
            this.desserts = [];
            toast(this, data.message || "stock_added_successfully");
          } else {
            toast(this, data.message || "error_adding_stock", "error");
          }
          this.overlay = false;
        }
      } catch (error) {
        this.overlay = false;
        console.error("Error adding stock:", error);
        toast(this, "error_adding_stock", "error");
      }
    },

    // Helper method to extract vendor ID
    getVendorId(vendor) {
      if (!vendor) return null;
      return typeof vendor === "object" ? vendor.id : vendor;
    },

    // Helper method to get exchange rate for a currency
    getExchangeRate(currencyCode) {
      // Find the first item with this currency to get its exchange rate
      const item = this.desserts.find(
        (item) => item.currency && item.currency.code === currencyCode
      );
      return item ? item.exchange_rate : 1;
    },
  },
};
</script>

<style scoped>
.product-card,
.cart-card {
  border-radius: 12px;
  transition: all 0.3s;
}

.product-card:hover,
.cart-card:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.product-list-container {
  height: calc(100vh - 210px);
  min-height: 400px;
  max-height: 75vh;
}

.product-list-item {
  border-radius: 8px;
  margin-bottom: 4px;
  transition: all 0.2s;
}

.product-list-item:hover {
  background-color: rgba(0, 0, 0, 0.03);
}

.product-name {
  font-weight: 500;
  font-size: 0.95rem;
  line-height: 1.3;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.price-text {
  font-weight: 600;
  color: #1976d2;
}

.quantity-control {
  display: flex;
  align-items: center;
  justify-content: center;
}

.quantity-value {
  min-width: 30px;
  text-align: center;
  font-weight: 500;
}

.action-buttons {
  display: flex;
  justify-content: flex-end;
}

.summary-section {
  background-color: #f5f7fa;
  border-radius: 12px;
  padding: 16px;
  margin-bottom: 16px;
}

.summary-card {
  height: 100%;
  transition: transform 0.2s;
  background-color: #fff;
}

.summary-card:hover {
  transform: translateY(-3px);
}

.total-summary-card {
  background-color: #f5f7fa;
}

.add-stock-btn {
  height: 56px;
  font-weight: 600;
  letter-spacing: 0.5px;
}

.purchase-table {
  border-radius: 8px;
}

/* Custom scrollbar */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

@media (max-width: 960px) {
  .product-list-container {
    height: 400px;
  }
}
</style>
