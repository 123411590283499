<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>{{ $t("exchange_rate") }}</v-card-title>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="currencies"
              class="exchange-rate-table"
              hide-default-footer
            >
              <template v-slot:item.index="{ index }">
                {{ index + 1 }}
              </template>
              <template v-slot:item.symbol="{ item }">
                <span
                  :class="`flag-icon flag-icon-${item.code.toLowerCase()}`"
                ></span>
                {{ item.symbol }}
              </template>
              <template v-slot:item.exchange_rate="{ item }">
                {{
                  item.store_exchange_rate ?? item.exchange_rate | NumberFormat
                }}
              </template>
              <template v-slot:item.action="{ item }">
                <v-btn
                  class="mr-3"
                  x-small
                  fab
                  depressed
                  @click="getHistory(item)"
                >
                  <v-icon color="blue" x-small>fal fa-eye</v-icon>
                </v-btn>
                <v-btn x-small fab depressed @click="editItem(item)">
                  <v-icon color="green" x-small>fal fa-edit</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <app-custom-dialog
      v-model="dialog"
      :footer="true"
      title="modify_exchange_rate"
      @submit="submitForm"
      width="400"
    >
      <div slot="activator">
        <VuetifyMoney v-model="form.exchange_rate" label="rate" />
      </div>
    </app-custom-dialog>

    <app-custom-dialog
      v-model="dialogView"
      :footer="false"
      title="view_history"
    >
      <div slot="activator">
        <v-data-table
          :headers="headers2"
          :items="histories"
          class="exchange-rate-table"
          hide-default-footer
        >
          <template v-slot:item.index="{ index }">
            {{ index + 1 }}
          </template>

          <template v-slot:item.name="{ item }">
            {{ form.name }}
          </template>
          <template v-slot:item.exchange_rate="{ item }">
            {{ item.exchange_rate | NumberFormat }}
          </template>
          <template v-slot:item.status="{ item }">
            <v-chip
              small
              class="rounded-lg"
              :color="item.status === 'active' ? 'success' : 'error'"
              text-color="white"
            >
              {{ $t(item.status) }}
            </v-chip>
          </template>
          <template v-slot:item.created_at="{ item }">{{
            item.created_at | created_at
          }}</template>
        </v-data-table>
      </div>
    </app-custom-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { success } from "@/controllers/alert";
import { myMixin } from "@/mixin/main";
import VuetifyMoney from "@/components/VuetifyMoney";

export default {
  mixins: [myMixin],
  data: () => ({
    dialogView: false,
    dialog: false,
    overlay: false,
    currencies: [],
    histories: [],
    form: {},
  }),

  mounted() {
    this.fetExchange();
  },
  components: { VuetifyMoney },
  computed: {
    ...mapGetters({
      category: "index/category",
    }),

    titleStatus() {
      return this.update ? "update" : "new_data";
    },
    headers() {
      return [
        { text: this.$t("id"), value: "index" },
        { text: this.$t("flag"), value: "flag", sortable: false },
        { text: this.$t("currency"), value: "name", sortable: false },
        { text: this.$t("currency_code"), value: "code", sortable: false },
        { text: this.$t("currency_symbol"), value: "symbol", sortable: false },
        { text: this.$t("exchange_rate"), value: "exchange_rate" },
        {
          text: this.$t("action"),
          value: "action",
          align: "right",
          sortable: false,
        },
      ];
    },
    headers2() {
      return [
        { text: this.$t("id"), value: "index" },
        { text: this.$t("currency"), value: "name", sortable: false },
        { text: this.$t("exchange_rate"), value: "exchange_rate" },
        { text: this.$t("status"), value: "status", sortable: false },
        {
          text: this.$t("created_at"),
          value: "created_at",
          align: "right",
          sortable: false,
        },
      ];
    },
  },

  methods: {
    ...mapActions({
      fetch: "index/fetch",
    }),
    async fetExchange() {
      try {
        const { data } = await this.$http.get("get_currency");
        this.currencies = data.data;
      } catch (error) {
        console.log("error", error);
      }
    },
    editItem(item) {
      const rate = item.store_exchange_rate ?? item.exchange_rate;
      this.form = {
        id: item.id,
        exchange_rate: rate,
      };
      this.dialog = true;
    },
    async submitForm() {
      try {
        const body = {
          exchange_rate: this.form.exchange_rate,
          currency_id: this.form.id,
        };
        const { data } = await this.$http.post("exchange_rate", body);
        success(this, data.message);
        this.dialog = false;
        await this.fetExchange();
      } catch (error) {
        console.log("error", error);
      }
    },

    async getHistory(item) {
      try {
        const { data } = await this.$http.get(`exchange_rate/${item.id}`);
        this.form = item;
        this.histories = data.data;
        this.dialogView = true;
      } catch (error) {
        console.log("error", error);
      }
    },
  },
};
</script>

<style></style>
