<template>
  <div class="container-xl pa-0">
    <v-row>
      <AppList v-model="darkMode" @handle="handle" :items="items" />
      <v-col cols="9">
        <AppStoreDetail v-if="index === 0" :store="user.stores" />
        <AppStore v-if="index === 1" />
        <AppProfile v-if="index === 2" />
        <AppChangePassword v-else-if="index === 3" />
        <AppPackage
          v-else-if="index === 4"
          :items="packages"
          @makeOrder="makeOrder"
          @cancelOrder="cancelOrder"
          :myOrder="myOrder"
        />
        <AppBill v-else-if="index === 7" />
        <AppBankAccount v-else-if="index === 10" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AppList from "@/components/settings/list.vue";
import AppStoreDetail from "@/components/settings/store-detail.vue";
import AppStore from "@/components/settings/store.vue";
import AppProfile from "@/components/settings/profile.vue";
import AppChangePassword from "@/components/settings/change-password.vue";
import AppPackage from "@/components/settings/package.vue";
import AppFeedBack from "@/components/settings/feedback.vue";
import AppContract from "@/components/settings/contract.vue";
import { mapGetters, mapActions } from "vuex";
import TTextField from "@/components/global/TextField.vue";
import TextArea from "@/components/global/TextArea.vue";
import { myMixin } from "@/mixin/main.js";
import FileInput from "@/components/global/FileInput.vue";
import { toast, confirm } from "@/controllers/alert";
import { listSettings } from "@/utils/mock.js";
import AppBill from "@/components/settings/bills/bill.vue";
import AppBankAccount from "@/components/settings/bank_account.vue";

export default {
  components: {
    AppList,
    AppProfile,
    AppStoreDetail,
    AppStore,
    AppChangePassword,
    AppContract,
    AppFeedBack,
    FileInput,
    TTextField,
    TextArea,
    AppPackage,
    AppBill,
    AppBankAccount,
  },
  mixins: [myMixin],

  data: () => ({
    imgFile: null,
    darkMode: null,
    index: 0,
    dialog: false,
    update: false,
    myOrder: {},
    items: listSettings(),
  }),

  created() {
    this.fetchPackage();
    this.getMyOrder();
    const themes = localStorage.getItem("darkMode");
    if (themes) {
      if (themes.length === 4) {
        this.darkMode = true;
        this.$vuetify.theme.dark = true;
      } else {
        this.darkMode = false;
        this.$vuetify.theme.dark = false;
      }
    }
  },

  mounted() {
    if (this.store_type != "RESTAURANT") {
      // find value: 5 and remove
      this.items = this.items.filter((item) => item.value !== 5);
    }
    const index = localStorage.getItem("selectedItem");
    if (index) this.index = parseInt(index);
  },

  destroyed() {
    this.index = 0;
  },

  watch: {
    darkMode(value) {
      this.$vuetify.theme.dark = value;
      localStorage.setItem("darkMode", value);
    },
    imgFile(val) {
      if (val) {
        this.form.avatar = val;
      }
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      packages: "index/packages",
    }),

    Img() {
      if (this.imgFile) {
        let imageURL = URL.createObjectURL(this.imgFile);
        return imageURL;
      } else {
        return Help.product(this.form.logo);
      }
    },
  },
  methods: {
    ...mapActions({
      logout: "auth/logout",
      fetch: "index/fetch",
    }),

    async makeOrder(id) {
      try {
        const { isConfirmed } = await confirm({
          props: this,
          text: "want_to_order_package",
        });

        if (isConfirmed) {
          const body = {
            store_id: this.user.stores.id,
            package_id: id,
          };
          const { data } = await this.$http.post("make_order", body);
          if (data.status) {
            await toast(this, "order_success");
            await this.getMyOrder();
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async cancelOrder(id) {
      try {
        const { isConfirmed } = await confirm({
          props: this,
          text: "want_to_cancel_order",
          can_txt: "no_cancel_order",
          con_txt: "yes_cancel_order",
        });

        if (isConfirmed) {
          const { data } = await this.$http.put(`cancel_order/${id}`);
          if (data.status) {
            await toast(this, "cancel_order_success");
            await this.getMyOrder();
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getMyOrder() {
      try {
        const { data } = await this.$http.get("my_order");
        if (data.status) {
          this.myOrder = data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },

    async fetchPackage() {
      const body = {
        that: this,
        url: "packages",
        commit: "SET_PACKAGE",
      };
      await this.fetch(body);
    },

    handle(value) {
      if (value === 5 && this.store_type === "RESTAURANT") {
        // new tab for kitchen
        window.open("/kitchen", "_blank");
        return;
      } else if (value === 6) {
        // new tab for kitchen
        window.open("/customer-screen", "_blank");
        return;
      } else if (value === 8) {
        this.darkMode = !this.darkMode;
        return;
      } else if (value === 9) {
        this.logoutMethod();
        return;
      } else {
        this.index = value;
        return;
      }
    },
    async logoutMethod() {
      try {
        const { isConfirmed } = await confirm({
          props: this,
          text: "want_to_logout",
        });
        if (isConfirmed) {
          await this.logout(this);
          window.location.href = "/";
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style></style>
