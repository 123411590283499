<template>
  <section>
    <h3 class="mb-3">
      {{ $t("overview") }}
    </h3>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <div class="row">
          <template v-if="top_summaries.length">
            <div
              class="col-xs-12 col-sm-12 col-md-6 col-lg-6"
              v-for="(item, index) in top_summaries"
              :key="index"
            >
              <v-card rounded="lg" height="185">
                <v-card-text>
                  <div class="mb-2 text-capitalizes title">
                    {{ $t(item.text) }}
                  </div>
                  <v-list-item>
                    <v-list-item-avatar
                      size="70"
                      :color="item.color"
                      rounded="xl"
                    >
                      <v-icon color="white" size="40">{{ item.icon }}</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="text-h5 mb-2">
                        {{ item.value | NumberFormat }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        {{ $t(item.description) }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card-text>
              </v-card>
            </div>
          </template>
          <div
            v-else
            class="col-xs-12 col-sm-12 col-md-6 col-lg-6"
            v-for="(item, index) in 4"
            :key="index"
          >
            <v-card rounded class="rounded-lg">
              <v-card-text>
                <v-skeleton-loader
                  type="table-heading, list-item-avatar-three-line"
                />
              </v-card-text>
            </v-card>
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <v-card rounded="lg">
          <v-card-title> {{ $t("profit") }} </v-card-title>

          <v-card-text>
            <div id="chart" v-if="series1.length > 0">
              <apexchart
                width="100%"
                height="290"
                type="area"
                :options="option"
                :series="series1"
              ></apexchart>
            </div>
          </v-card-text>
        </v-card>
      </div>

      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <v-card rounded="lg">
          <v-card-title> {{ $t("total_sale") }} </v-card-title>
          <v-card-text>
            <apexchart
              v-if="series2.length > 0"
              type="bar"
              height="320"
              :options="chartOptions2"
              :series="series2"
            ></apexchart>
          </v-card-text>
        </v-card>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6">
        <v-card rounded="lg">
          <v-card-title>{{ $t("best_sell") }} </v-card-title>

          <v-card-text>
            <v-data-table
              :headers="top_sell_headers"
              :items="top_sells"
              :items-per-page="6"
              class="elevation-0"
              no-data-text="ບໍ່ມີຂໍ້ມູນ"
              hide-default-footer
            >
              <template v-slot:item.index="{ index }">
                <span>{{ index + 1 }}</span>
              </template>
              <template v-slot:item.index="{ index }">
                <span>{{ index + 1 }}</span>
              </template>
              <template v-slot:item.img="{ item }">
                <v-avatar
                  tile
                  size="40"
                  class="rounded-lg"
                  color="grey lighten-2"
                >
                  <app-img :img="item.img" />
                </v-avatar>
              </template>
              <template v-slot:item.price_sale="{ item }">
                {{ item.price_sale | NumberFormat }}
              </template>
              <template v-slot:item.histories_sum_qty="{ item }">
                {{ item.histories_sum_qty | NumberFormat }}
              </template>
              <template v-slot:item.histories_sum_total_price="{ item }">
                {{ item.histories_sum_total_price | NumberFormat }}
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </section>
</template>

<script>
import ApexCharts from "vue-apexcharts";
import { myMixin } from "@/mixin/main";
import { HeadTop } from "@/utils/head";
export default {
  components: {
    apexchart: ApexCharts,
  },
  mixins: [myMixin],

  data: () => ({
    option: {},
    series1: [],
    chartOptions2: {},
    series2: [],
    top_summaries: [],

    top_sells: [],
  }),
  mounted() {
    this.top_summary();
    this.summary_revenue();
    this.order_tracking();
    this.top_selling();
  },
  computed: {
    top_sell_headers() {
      return HeadTop(this);
    },
  },
  methods: {
    async top_summary() {
      try {
        const { data } = await this.$http.get("/top_summary");
        this.top_summaries = data.data;
      } catch (error) {
        console.log(error);
      }
    },
    async summary_revenue() {
      try {
        const { data } = await this.$http.get("/summary_revenue");
        const series = Help.pluck(data.data.data, "price");
        const labels = Help.pluck(data.data.data, "month");
        this.series1 = [
          {
            name: "",
            data: series,
          },
        ];
        this.option = {
          colors: ["#5b39c9"],
          chart: {
            type: "area",
            fontFamily: "NotoSansLaoUI",
            zoom: {
              enabled: false,
            },
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "straight",
          },
          series: [
            {
              name: "",
              data: series,
            },
          ],
          title: {
            text: `${this.$t("yearly")} ${this.formatNumber(data.data.total)}`,
            align: "left",
          },
          labels: labels,
          yaxis: {
            opposite: true,
            labels: {
              formatter: (val) => {
                return this.formatNumber(val);
              },
            },
          },
          legend: {
            horizontalAlign: "left",
          },
          tooltip: {
            y: {
              formatter: (val) => {
                return this.formatNumber(val);
              },
            },
          },
        };
      } catch (error) {
        console.log(error);
      }
    },
    async order_tracking() {
      try {
        const { data } = await this.$http.get("/order_tracking");
        const series = Help.pluck(data.data, "count");
        const labels = Help.pluck(data.data, "month");
        this.series2 = [
          {
            name: "",
            data: series,
          },
        ];
        this.chartOptions2 = {
          colors: ["#5b39c9"],
          chart: {
            type: "pie",
            fontFamily: "NotoSansLaoUI",
            zoom: {
              enabled: false,
            },
          },
          tooltip: {
            y: {
              formatter: (val) => {
                return this.formatNumber(val);
              },
            },
          },
          toolbar: {
            show: false,
          },
          labels: labels,
          yaxis: {
            opposite: true,
            labels: {
              formatter: (val) => {
                return this.formatNumber(val);
              },
            },
          },
        };
      } catch (error) {
        console.log(error);
      }
    },
    async top_selling() {
      try {
        const { data } = await this.$http.get("/top_selling");
        this.top_sells = data.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style></style>
