<template>
  <div class="product-details-container">
    <!-- Product Header -->
    <div class="product-header">
      <div class="product-image-container">
        <app-img
          width="100%"
          height="100%"
          :img="item.img"
          class="product-image"
        />
      </div>

      <div class="product-header-info">
        <h1 class="product-name">{{ item.name }}</h1>
        <div class="product-code">
          {{ $t("code") }}: <span>{{ item.code }}</span>
          <v-btn
            fab
            class="rounded-lg ml-2"
            depressed
            x-small
            @click="copyToClipboard(item.code)"
          >
            <v-icon small> fal fa-copy </v-icon>
          </v-btn>
        </div>
        <div class="product-category">
          {{
            $i18n.locale === "en"
              ? item.category.en_name
              : item.category.la_name
          }}
        </div>
        <div class="price-tag">
          <span class="price-sale">{{ item.price_sale | NumberFormat }}</span>
          <v-chip
            v-if="item.size"
            label
            dark
            color="green accent-3"
            small
            class="size-chip"
          >
            {{
              item.size.unit === "amount"
                ? $t("amount")
                : `${$t("pack")}|${item.size.amount}`
            }}
          </v-chip>
        </div>
      </div>
    </div>

    <!-- Information Cards -->
    <div class="info-cards">
      <div class="info-card">
        <div class="info-card-title">{{ $t("stock") }}</div>
        <div class="info-card-value">
          {{ item.stock | NumberFormat }}
          <span class="caption">
            / {{ item.notify_stock | NumberFormat }}
          </span>
        </div>
      </div>

      <div class="info-card">
        <div class="info-card-title">{{ $t("price_buy") }}</div>
        <div class="info-card-value">
          {{ item.price_buy | NumberFormat }}
          <span class="caption" v-if="item.exchange_rate > 1">
            / {{ original_buy_price }}
          </span>
        </div>
      </div>

      <div class="info-card">
        <div class="info-card-title">{{ $t("profit") }}</div>
        <div class="info-card-value">
          {{ profit | NumberFormat }}
          <span class="caption">
            / {{ item.profit_percent | NumberFormat }}%
          </span>
        </div>
      </div>

      <div class="info-card">
        <div class="info-card-title">{{ $t("total_sale") }}</div>
        <div class="info-card-value">
          {{ item.history_sum_total_price | NumberFormat }}
        </div>
      </div>
    </div>

    <!-- Details Section -->
    <div class="details-section">
      <h2 class="section-title">{{ $t("product_details") }}</h2>

      <div class="detail-row">
        <div class="detail-label">{{ $t("supplier") }}</div>
        <div class="detail-value">{{ supplier }}</div>
      </div>

      <div class="detail-row">
        <div class="detail-label">{{ $t("total_sold_qty") }}</div>
        <div class="detail-value">
          {{ item.history_sum_qty | NumberFormat }}
        </div>
      </div>

      <div v-if="item.size" class="detail-row">
        <div class="detail-label">{{ $t("unit") }}</div>
        <div class="detail-value">{{ item.size.name }}</div>
      </div>
      <template v-if="taxInclude">
        <div class="detail-row">
          <div class="detail-label">{{ $t("tax") }} {{ tax }}</div>
          <div class="detail-value">{{ item.tax_amount | NumberFormat }}</div>
        </div>

        <div class="detail-row">
          <div class="detail-label">{{ $t("price_with_tax") }}</div>
          <div class="detail-value">{{ item.price_sale | NumberFormat }}</div>
        </div>
      </template>
      <div class="detail-row" v-if="item.exchange_rate > 1">
        <div class="detail-label">{{ $t("cost_currency") }}</div>
        <div class="detail-value">
          {{ item.exchange_rate | NumberFormat }}/{{ item.currency.code }}
        </div>
      </div>

      <div class="detail-row">
        <div class="detail-label">{{ $t("cash_received") }}</div>
        <div class="detail-value">
          {{
            (item.history_sum_total_price - item.history_sum_discount)
              | NumberFormat
          }}
        </div>
      </div>

      <div class="detail-row">
        <div class="detail-label">{{ $t("expired_date") }}</div>
        <div class="detail-value">
          {{ item?.expired_date ?? $t("none_expired") }}
        </div>
      </div>

      <div class="detail-row">
        <div class="detail-label">{{ $t("created_at") }}</div>
        <div class="detail-value">{{ item.created_at | moment }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { myMixin } from "@/mixin/main";
export default {
  mixins: [myMixin],
  props: {
    item: {
      type: Object,
    },
  },
  computed: {
    supplier() {
      if (
        this.item.vendor &&
        this.item.vendor.name &&
        this.item.vendor.phone_no
      ) {
        return this.item.vendor.name + " - " + this.item.vendor.phone_no;
      } else if (this.item.vendor && this.item.vendor.name) {
        return this.item.vendor.name;
      } else if (this.item.vendor && this.item.vendor.phone_no) {
        return this.item.vendor.phone_no;
      } else {
        return "";
      }
    },

    profit() {
      const buy = this.item.price_buy;
      const sale = this.item.price_sale;
      const tax = this.item.tax_amount;
      const a = sale - tax;
      return a - buy;
    },
    original_buy_price() {
      const total = this.item.price_buy / this.item.exchange_rate;
      const code = this.item.currency.code;
      return `${this.$options.filters.NumberFormat(total)} ${code}`;
    },
  },
};
</script>

<style scoped>
.product-details-container {
  padding: 24px;
  background-color: #f9f9f9;
  border-radius: 12px;
}

.product-header {
  display: flex;
  margin-bottom: 32px;
  gap: 32px;
  align-items: center;
}

.product-image-container {
  width: 200px;
  height: 200px;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  background-color: white;
}

.image-btn {
  width: 100%;
  height: 100%;
  padding: 0;
}

.product-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.product-header-info {
  flex: 1;
}

.product-name {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 8px;
  color: #333;
}

.product-code {
  font-size: 14px;
  color: #777;
  margin-bottom: 8px;
}

.product-code span {
  font-weight: 600;
  color: #555;
}

.product-category {
  display: inline-block;
  margin-bottom: 16px;
  background-color: #e7f0ff;
  color: #2c64c6;
  font-size: 14px;
  padding: 4px 12px;
  border-radius: 20px;
  font-weight: 500;
}

.price-tag {
  display: flex;
  align-items: center;
  gap: 12px;
}

.price-sale {
  font-size: 28px;
  font-weight: 700;
  color: #2c64c6;
}

.size-chip {
  margin-left: 8px;
}

.info-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
  margin-bottom: 32px;
}

.info-card {
  background-color: white;
  padding: 16px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s, box-shadow 0.2s;
}

.info-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.info-card-title {
  font-size: 14px;
  color: #777;
  margin-bottom: 8px;
}

.info-card-value {
  font-size: 24px;
  font-weight: 600;
  color: #333;
}

.section-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #333;
  border-bottom: 2px solid #eee;
  padding-bottom: 8px;
}

.details-section {
  background-color: white;
  border-radius: 10px;
  padding: 24px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
}

.detail-row {
  display: flex;
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #eee;
}

.detail-row:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.detail-label {
  width: 180px;
  color: #777;
  font-size: 15px;
}

.detail-value {
  flex: 1;
  font-weight: 500;
  color: #333;
}

@media (max-width: 768px) {
  .product-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .product-image-container {
    width: 100%;
    height: 250px;
    margin-bottom: 24px;
  }

  .detail-row {
    flex-direction: column;
  }

  .detail-label {
    width: 100%;
    margin-bottom: 4px;
  }
}
</style>
