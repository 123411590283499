<template>
  <section>
    <v-row class="mb-3">
      <template v-if="summaries.length">
        <v-col
          v-for="(item, index) in summaries"
          :key="index"
          class="col-xs-6 col-sm-4 col-md-3 cols-5"
        >
          <v-card rounded="lg">
            <v-card-text
              :class="$vuetify.breakpoint.mdAndUp ? 'px-4' : 'px-0'"
              class="px-0"
            >
              <v-list-item>
                <v-list-item-avatar :color="item.color" rounded="lg">
                  <v-icon color="white">
                    {{ item.icon }}
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-subtitle>
                    {{ $t(item.title) }}
                  </v-list-item-subtitle>
                  <v-list-item-title class="text-h6 font-weight-bold">
                    <span v-if="index <= 1">
                      {{ item.total | NumberFormat }}
                    </span>
                    <span v-else>
                      {{ item.total | NumberFormat }}
                    </span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-card-text>
          </v-card>
        </v-col>
      </template>
      <v-col v-else v-for="(item, index) in 5" :key="index">
        <v-card rounded class="rounded-lg">
          <v-card-text class="pa-2">
            <v-skeleton-loader type="list-item-avatar-three-line" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <data-table
      :headers="headers"
      :items="allProducts"
      commit="SET_ALL_PRODUCTS"
      :viewItem="viewItem"
      :editItem="editItem"
      :deleteItem="deleteItem"
      :proStatus="proStatus"
    >
      <div slot="activator" class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
          <div class="d-flex">
            <v-card-title class="py-0 pl-0">
              {{ $t("all_product") }}
            </v-card-title>
            <v-chip-group
              v-model="status_filter"
              mandatory
              column
              active-class="deep-purple--text text--accent-4"
            >
              <v-chip
                class="rounded-lg"
                v-for="(val, i) in status_list"
                :key="i"
                :value="val.value"
                filter
                outlined
              >
                {{ $t(val.text) }}
              </v-chip>
            </v-chip-group>
          </div>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-2 text-right">
          <TextSelect
            :dense="true"
            :placeholder="false"
            :items="categories"
            v-model="category_id"
            :text="$i18n.locale === 'en' ? 'en_name' : 'la_name'"
            itemValue="id"
          />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-3 text-right">
          <TextSearch
            v-model="search"
            @search="fetchItem()"
            @onClear="
              search = '';
              fetchItem();
            "
          />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-3 text-right">
          <v-btn
            min-width="auto"
            class="mr-3"
            depressed
            outlined
            color="primary"
            to="/trash-products"
          >
            <v-icon small>fal fa-trash</v-icon>
          </v-btn>
          <v-btn
            min-width="auto"
            depressed
            dark
            color="primary"
            to="/manage-add-product"
          >
            <v-icon left small>fal fa-plus-circle</v-icon>
            {{ $t("add_new_product") }}
          </v-btn>
        </div>
      </div>
    </data-table>

    <app-custom-dialog
      v-model="dialog"
      :footer="false"
      title="description"
      @submit="submitForm"
    >
      <div slot="activator">
        <AppDetail :item="form" />
      </div>
    </app-custom-dialog>
    <app-loading :overlay="overlay" />
  </section>
</template>

<script>
import DataTable from "@/components/DataTable";
import { mapGetters, mapActions, mapMutations } from "vuex";
import { productHead } from "@/utils/head";
import AppDetail from "@/components/products/details.vue";
import { myMixin } from "@/mixin/main";
import { success, confirm } from "@/controllers/alert";
import TextSelect from "@/components/global/TextSelect";
import { productSort } from "@/utils/mock";

export default {
  mixins: [myMixin],
  data: () => ({
    search: "",
    status_filter: "desc",
    status_list: productSort(),
    category_id: {
      id: 0,
      la_name: "ທັງໝົດ",
      en_name: "All",
    },
    update: false,
    dialogAdd: false,
    dialog: false,
    form: {},
    summaries: [],
  }),
  async created() {
    await this.fetchSummary();
    await this.fetchItem();
    await this.fetchCategory();
  },
  watch: {
    category_id(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.search = "";
        this.fetchItem();
      }
    },
  },
  components: {
    DataTable,
    AppDetail,
    TextSelect,
  },
  computed: {
    ...mapGetters({
      overlay: "index/overlay",
      category: "index/category",
      allProducts: "index/allProducts",
    }),
    headers() {
      return productHead(this);
    },

    categories() {
      if (
        this.category != null &&
        this.category != undefined &&
        this.category.length
      ) {
        const object = {
          id: 0,
          la_name: "ທັງໝົດ",
          en_name: "All",
        };
        this.category.unshift(object);
        return this.category;
      } else {
        return [];
      }
    },
  },
  watch: {
    status_filter(val, oldVal) {
      if (val !== oldVal) {
        this.fetchItem();
      }
    },
    category_id(val, oldVal) {
      if (val !== oldVal) {
        this.fetchItem();
      }
    },
  },

  methods: {
    ...mapActions({
      fetch: "index/fetch",
    }),
    ...mapMutations({
      SET_OVERLAY: "index/SET_OVERLAY",
    }),
    async fetchCategory() {
      const body = {
        url: "categories",
        that: this,
        commit: "SET_CATEGORY",
      };
      await this.fetch(body);
    },
    async fetchItem() {
      const body = {
        that: this,
        url: `products_all?cat_id=${this.category_id.id}&q=${this.search}&sort=${this.status_filter}`,
        commit: "SET_ALL_PRODUCTS",
      };
      await this.fetch(body);
    },

    async newItem() {
      await Help.clearForm(this.form, this);
      await this.fetchItem();
      this.update = false;
      this.dialogAdd = !this.dialogAdd;
    },
    editItem({ id }) {
      this.$router.push(`/manage-edit-product/${id}`);
    },
    async fetchSummary() {
      try {
        const { data } = await this.$http.get(`/get_summary`);
        if (data.status) {
          this.summaries = data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async deleteItem({ id }) {
      const { isConfirmed } = await confirm({
        props: this,
        text: "want_to_delete",
      });
      if (isConfirmed) {
        const { data } = await this.$http.delete(`products/${id}`);
        if (data.status) {
          await Help.clearForm(this.form, this);
          await this.fetchItem();
          await success(this, data.message);
        }
      }
    },
    async viewItem({ id }) {
      try {
        const { data } = await this.$http.get(`products/${id}`);
        if (data.status) {
          this.dialog = !this.dialog;
          this.form = data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async submitForm() {
      var result;
      try {
        if (this.update) {
          result = await this.$http.post(
            `product_size/${this.form.id}`,
            this.form
          );
        } else {
          result = await this.$http.post("product_size", this.form);
        }
        if (result.data.status) {
          this.dialogAdd = !this.dialogAdd;
          await Help.clearForm(this.form, this);
          await this.fetchItem();
          await success(this, result.data.message);
        }
      } catch (error) {
        console.log("error", error);
      }
    },

    async proStatus(val) {
      try {
        const { data } = await this.$http.put(`product_active/${val.id}`);
        if (data.status) {
          await this.fetchItem();
          await success(this, data.message);
        } else {
          await errorAlert(this, data.message);
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style></style>
